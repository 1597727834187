//@see ~bootstrap/scss/_variables.css

// Don't use custom scss colors in this project
// Use CSS variables via `theme.css.twig`



$color-form-validation-error: #C60505;
$color-form-required: #C60505;
$color-form-validation-error-text: white;

$color-visual-editor-block: #c1c1c1;
$color-visual-editor-block-active: #5f5f5f;
$color-visual-editor-block-group: #868686;


.b-topics{
  .c-button+.c-button{
    margin-left: 0;
    margin-top: 0;
  }
}

.b-topics__tag{
  margin-right: 0.8rem;
}

.c-kiosk-highlighted{
  background-color: rgba(0,0,0,0.05);
  min-height: 360px;
}

.c-kiosk-highlighted__content{
  background-color: white;
  padding: 1.5rem;
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
  border-radius: $border-radius;
  margin: 2.8rem;

  @include media-breakpoint-down(sm) {
    margin:1.5rem;
  }

  @include media-breakpoint-down(xs) {
    padding: 1rem;
    max-width: 100%;
    margin: 1rem;
  }
}


.c-kiosk-highlighted__badge{
  font-size: 0.611rem;
  text-transform: uppercase;
  color: var(--color-grey-5);
}

.c-kiosk-highlighted__title{
  font-size: 1.111rem;
  font-weight: 600;
}

.c-kiosk-highlighted__summary{
  width: 350px;
  max-width: 100%;
  font-size: 0.889rem;

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.c-kiosk-highlighted__image{
  height: 360px;

  @include media-breakpoint-down(sm) {
    height: 320px;
  }

  img{
    object-fit: contain;
    height: calc(360px + 3rem);
    margin: -1.5rem 2rem;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
    @include media-breakpoint-down(md) {
      margin: 1rem;
      height: 360px;
    }

    @include media-breakpoint-down(sm) {
      height: 320px;
    }
    @include media-breakpoint-down(xs) {
      height: 280px;
      margin: -1rem 1rem 0.5rem;
      display: block;
    }

  }


}


.c-kiosk-card{
  &:hover{
    .c-kiosk-card__hover {
      visibility: visible;
      transition: $transition-default;
    }
  }
  &:hover,:active{
    .c-kiosk-card__title-link{
     color: var(--color-secondary);
     text-decoration: none;
     .c-item-card__title {
       text-decoration: underline;
     }
     svg{
       path{
         fill: currentColor;
       }
     }
   }
  }

  &:hover{
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.16);
  }

  &:active {
    background: var(--color-grey-2);
  }
  @include media-breakpoint-down(md) {
    justify-content: space-between;
    flex-direction: column !important;
  }
}

.c-kiosk-card__top-container{
  position: relative;

}
.c-kiosk-card__image{
  width: 100%;
  height: 100%;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.c-kiosk-card__hover{
  visibility: hidden;
  background-color: var(--color-primary);
  transition: $transition-default;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
}

.c-kiosk-card__hover-content{
  text-align: center;
  margin: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  a, span{
    color: white;
    display: block;
    margin: 1rem 0;
    i{
      padding-right: 0.4rem;
    }
  }
}

.c-kiosk-card__title{

  padding-bottom: 0.5rem;
}

.c-kiosk-card__title-link{
  min-height: 55px;
}

.c-kiosk-card--nolink{

  &:hover,:active{
      .c-item-card__title {
        color: var(--color-dark)!important;
        text-decoration: none!important;
      }
    }


  &:hover{
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.16);
  }

  &:active {
    background: var(--color-light)!important;
  }
}



//todo: remove unused styles

.t-post-geolocation-search {
  .container-fullwidth {
    width: 100vw;
    padding: 0 0.9375rem;
  }

  a[href^="http://maps.google.com/maps"] {
    display: none !important;
  }

  a[href^="https://maps.google.com/maps"] {
    display: none !important;
  }

  .gmnoprint a,
  .gmnoprint span,
  .gm-style-cc {
    display: none;
  }
  .c-filter__selected-filter{
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
  }
}

.t-post-geolocation-search__aside {
  min-width: 340px;
}

.t-post-geolocation-search__map-container {
  height: calc(100vh - 7.25rem);

  @media screen and (min-width: 992px) {
    //max-width: 100%;
    position: fixed !important;
    top: 7.25rem;
    bottom: 0;
    right: 0;
  }
}

.t-post-geolocation-search__map {
  width: 100%;
  height: 100%;
}

.t-post-geolocation-search__search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6rem;
}

.t-post-geolocation-search__search-bar {
  position: relative;
  flex-grow: 5;
  margin-right: 0.75rem;

  i {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }
}

.t-post-geolocation-search__search_input {
  padding-left: 2.5rem !important;
}

.t-post-geolocation-search__result-found {
  margin: 0.75rem 0 1.5rem;

  &[data-active="0"] {
    display: none;
  }
}

.t-post-geolocation-search__load-more {
  display: block;
  margin: 0 auto;

  &.is-hidden {
    display: none;
  }

  &[data-has-next="0"] {
    display: none;
  }
}

.t-post-geolocation-search__breadcrumbs {
  margin-bottom: 1.75rem;
  font-size: .77rem;

  svg {
    width: 0.667rem;
    height: auto;
    margin-left: 0.5rem;
  }

  span {
    font-size: 0.77rem;
  }
}

.t-post-geolocation-search__page-title {
  font-size: 2.25rem;
  margin-bottom: 1.25rem;
}

.t-post-geolocation-search__page-description {
  display: block;
}

.t-post-geolocation-search__filters {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  //max-width: 500px;
}

.t-post-geolocation-search__filter-buttons {
  display: flex;
  flex-direction: column;
}

.t-post-geolocation-search__filter-toggle {
  position: relative;
  min-width: 275px;
  padding: 0.9375rem 1.25rem 0.875rem 1.25rem !important;
  background-color: hsl(0deg 0% 95%) !important;
  color: hsl(60deg 2% 11%) !important;
  font-size: 1rem !important;
  line-height: 1.0625 !important;
  border-radius: $border-radius;

  display: flex;
  justify-content: space-between;
  align-items: center;


  &:hover {
    color: hsl(60deg 2% 11%);
  }

}

.t-post-geolocation-search__filter-options {
  overflow: hidden;
  transform: scaleY(0);
  max-height: 0;
  transition: transform 0.3s ease-in-out, max-height 0.3s ease-in-out;
  transform-origin: top;
  padding: 0 20px;
  max-width: 275px;

  input[type="radio"] + label::before,
  input[type="checkbox"] + label::before {
    border: 1px solid var(--color-grey-5) !important;
    background: none;
  }
  input[type="radio"]:checked + label::before,
  input[type="checkbox"]:checked + label::before {
    background: var(--color-primary) ;
  }
}

.t-post-geolocation-search__filter-dropdown {
  min-width: 275px;
  margin: 0.6rem 0 0.5rem;

  &.is-open {
    background: hsl(0deg 0% 95%) !important;
    .t-post-geolocation-search__filter-toggle {
      margin-bottom: 0.5rem;

      i, svg{
        transform: rotate(180deg);
      }
    }

    .t-post-geolocation-search__filter-options {
      transform: scaleY(1);
      max-height: 10rem;
      overflow-y: scroll;
      transform-origin: top;
      label{
        background: none;
      }
    }
  }
}

.t-post-geolocation-search__apply-filter {
  width: 100%;
  margin-bottom: 0.5rem;
}

.t-post-geolocation-search__loading {
  @media screen and (min-width: 500px) {
    height: 200vw;
  }

  width: 100%;
  display: none;
  position: absolute;
  background-color: rgb(255 255 255 / 50%);
  z-index: 99;
}

.t-post-geolocation-search__loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 43.75rem;
}

@media screen and (max-width: 992px) {
  .t-post-geolocation-search__search-results {
    display: none;
  }
}

.t-post-geolocation-search__loading.is-loading {
  display: inline-block;
  background-color: rgb(255 255 255 / 50%);

  .t-post-geolocation-search__loader {
    position: relative;
    width: 5rem;
    height: 5rem;

    div {
      background-color: var(--color-primary);
      position: absolute;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      animation: maps-lds-grid 1.2s linear infinite;

      &:nth-child(1) {
        top: 0.5rem;
        left: 0.5rem;
        animation-delay: 0s;
      }

      &:nth-child(2) {
        top: 0.5rem;
        left: 2rem;
        animation-delay: -0.4s;
      }

      &:nth-child(3) {
        top: 0.5rem;
        left: 3.5rem;
        animation-delay: -0.8s;
      }

      &:nth-child(4) {
        top: 2rem;
        left: 0.5rem;
        animation-delay: -0.4s;
      }

      &:nth-child(5) {
        top: 2rem;
        left: 2rem;
        animation-delay: -0.8s;
      }

      &:nth-child(6) {
        top: 2rem;
        left: 3.5rem;
        animation-delay: -1.2s;
      }

      &:nth-child(7) {
        top: 3.5rem;
        left: 0.5rem;
        animation-delay: -0.8s;
      }

      &:nth-child(8) {
        top: 3.5rem;
        left: 2rem;
        animation-delay: -1.2s;
      }

      &:nth-child(9) {
        top: 3.5rem;
        left: 3.5rem;
        animation-delay: -1.6s;
      }
    }
  }
}

@keyframes maps-lds-grid {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.c-post-geolocation-search-result-card {
  position: relative;
  margin-bottom: 0.5rem;
  box-shadow: 0 0.125rem 1rem rgb(0 0 0 / 8%);
  padding: 1.25rem;
  background-color: var(--color-card-background);
}

.c-post-geolocation-search-result-card__name {
  font-size: 1rem;
  line-height: 1.09;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.c-post-geolocation-search-result-card__address {
  display: inline-block;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
}

.c-post-geolocation-search-result-card__website,
.c-post-geolocation-search-result-card__phone,
.c-post-geolocation-search-result-card__email {
  font-size: 0.875rem;
  display: flex;
  margin-bottom: 0.25rem;
  white-space: break-spaces;
  color: var(--color-primary);

  i {
    font-size: 0.875rem;
    margin-right: 0.75rem;
    color: var(--color-dark);
  }
}

//.c-post-geolocation-search-result-card__website {
//  color: var(--color-primary);
//
//  i {
//    color: var(--color-dark);
//  }
//}

.c-post-geolocation-search-result-card__socials a:hover {
  text-decoration: none;
}

.c-post-geolocation-search-result-card__employees {
  margin-bottom: 0.75rem;
}

.c-post-geolocation-search-result-card__employees-title {
  font-size: 0.875rem;
  font-weight: 500;
  margin: 1rem 0 0.25rem;
}

.c-post-geolocation-search-result-card__employee {
  font-size: 0.875rem;
  line-height: 1.4;
}

.c-post-geolocation-search-result-card__specifics {
  font-style: italic;
}

.c-post-geolocation-search-result-card__button {
  white-space: nowrap;
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  min-width: 7rem;
  align-self: end;
}

.c-post-geolocation-search-result-card__toggle-info {
  white-space: nowrap;
  position: relative;
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  padding: 0.6875rem 1.8125rem 0.625rem 1rem !important;
  min-width: 9.75rem;
  align-self: end;

  &.is-loading {
    .c-post-geolocation-search-result-card__spinner {
      z-index: 99;
    }
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    top: 0.9375rem;
    right: 0.75rem;
    background-color: var(--color-light);
  }

  &::before {
    margin: 0 0.25rem;
    width: 0.0625rem;
    height: 0.5625rem;
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
  }

  &::after {
    margin: 0.25rem 0;
    width: 0.5625rem;
    height: 0.0625rem;
  }
}

.c-post-geolocation-search-result-card__columns {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  .c-post-geolocation-search-result-card__more-info {
    transform: scaleY(0);
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    transition: transform 0.3s ease-in-out, max-height 0.3s ease-in-out, opacity 0.2s ease-in-out;
    transform-origin: top;
  }

  &.is-open {
    .c-post-geolocation-search-result-card__more-info {
      transform: scaleY(1);
      opacity: 1;
      transform-origin: top;
      max-height: none;
      transition: transform 0.3s ease-in-out, max-height 0.3s ease-in-out, opacity 0.2s ease-in-out;
    }

    .c-post-geolocation-search-result-card__toggle-info::before {
      transform: rotate(90deg);
      transition: transform 0.3s ease-in-out;
    }

    .c-post-geolocation-search-result-card__employees {
      display: block;
    }
  }
}

.c-post-geolocation-search-result-card__spinner {
  background-color: hsl(188deg 100% 28% / 50%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 0.6875rem 1.8125rem 0.625rem 1rem;
  z-index: -1;
}

.c-post-geolocation-search-result-card {
  &.marker-modal {
    font-family: var(--font-family) !important;
    font-size: 0.875rem !important;
    color: var(--color-dark) !important;
    box-shadow: none;
    padding: 0;
    margin: 0.5rem;

    i {
      //display: none;
    }

    .c-post-geolocation-search-result-card__columns {
      flex-direction: column;

    }

    .c-post-geolocation-search-result-card__column--button {
      margin-top: 1rem;
      align-self: center;
    }
  }
}

.c-employer-socials {
  color: var(--color-primary);
  margin-right: 0.75rem;
  font-size: 1.25rem;
}

.gm-style-iw-chr button span, .gm-style-iw-chr button{
  height: 16px!important;
  width: 16px!important;
  margin:0!important;
}
.gm-style-iw-chr{
  margin-top: 6px;
  margin-right: 6px;
  height: 6px!important;
  justify-content: space-between;
}

.t-post-geolocation-search__breadcrumbs>*{
  margin-right:0.5rem;
}

.c-post-geolocation-search-result-card__intro{
  font-size: 14px;
  margin-right: 0.5rem;
}
.c-post-geolocation-search-result-card__categories{
    font-size: 0.875rem;
}

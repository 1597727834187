.b-raster {

}

.b-raster__title {
  font-size: 2rem;
  line-height: 2.125rem;
  font-weight: $font-weights-bold;
  margin-bottom: 1.5rem;

  @include media-breakpoint-down(md) {
    font-size: 1.625rem;
    line-height: 1.75rem;
    margin-bottom: 1rem;
  }
}

.b-raster__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;
  row-gap: 0.5rem;

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down(xs) {
    grid-template-columns: 1fr;
  }
}

.b-raster__grid-item {
  height: 294px;
  width: 100%;
  background-color: var(--color-primary);
  border-radius: 0.25rem;
  overflow: hidden;
  padding: 1.5rem;
  position: relative;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 16%);
}

.b-raster__image {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.b-raster__link {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;

  &:hover,
  &:focus {
    &::after {
      transform: translateX(4px);
    }
  }

  &::after {
    content: "";
    display: block;
    background-image: url("../img/arrow-right-white.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    transition: transform $transition-default;
    right: 1.5rem;
    bottom: 1.5rem;
    fill: black;
    color: black;
  }
}

.b-raster__link-title {
  font-size: 1rem;
  line-height: 1.1875rem;
  font-weight: $font-weights-semi-bold;
  margin-bottom: 0.75rem;
}

.b-raster__text {
  font-size: 0.9375rem;
  line-height: 1.5625rem;
}

.b-raster__grid-item--primary {
  background-color: var(--color-primary);
  color: var(--color-light);

  a {
    color: var(--color-light);
  }
}

.b-raster__grid-item--secondary {
  background-color: var(--color-secondary);
  color: var(--color-light);
}

.b-raster__grid-item--tertiary {
  background-color: var(--color-tertiary);
  color: var(--color-light);
}

.b-raster__grid-item--dark {
  background-color: var(--color-dark);
  color: var(--color-light);
}

.b-raster__grid-item--light {
  background-color: var(--color-light);
  color: var(--color-dark);

  .b-raster__link {
    &::after {
      background-image: url("../img/arrow-right.svg");
    }
  }
}

.b-raster__position--middle {
  text-align: center;
}

.b-raster__position--right {
  text-align: right;
}

.b-raster__margin-bottom {
  margin-bottom: 24px;
}

.b-raster__position {
  padding: 0;
  margin-top: 10px;
}

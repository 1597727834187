.c-managed-post-item-card {
  flex-direction: column !important;


  .c-item-card__image {
    max-height: 147px;
  }

  .c-item-card__image img {
    width: 100%;
    max-width: 100%;
    max-height: 300px;
  }

  .c-button + .c-button {
    margin-left: 1rem;
  }

  .c-item-card__icon {
    font-size: 4rem;
  }

  @include media-breakpoint-up(sm) {
    .c-item-card__title {
      height: 2.75rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }

}


.c-guideline-card__content {
  min-height: 106px;
  padding: 1rem;

  @include media-breakpoint-down(md) {
    max-width: calc(100% - 110px);
    width: 100%;

    &.wider {
      max-width: 100%;
      width: 100%;
    }
  }

  .c-item-card__title {
    margin-bottom: 0.1rem;
    margin-top: 0.3rem;
  }
}

.c-guideline-card__organisation {
  font-size: 0.6rem;
  color: var(--color-grey-5);
  letter-spacing: 1px;
  text-transform: uppercase;
}

.c-guideline-card__date {
  font-size: 0.667rem;
}

.mo_adjust_customize_button {
  .text-center & {
    display: flex !important;
    justify-content: center !important;
  }

  a {
    i {
      height: auto !important;
      width: fit-content !important;
      padding: 10px 20px 8px !important;
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: var(--color-primary) !important;

      &::before {
        font-size: 28px;
      }

      .mo_oauth_login_button_font {
        font-size: 16px;
        margin-left: 0 !important;
      }
    }
  }
}


.c-literature-card{
  @include media-breakpoint-down(md) {
    justify-content: space-between;
    flex-direction: column !important;
  }
}

.c-literature-card__top-container{


}

.c-literature-card__image{
  width: 100%;
  height: 100%;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

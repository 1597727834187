.c-photo-detail__title {
  margin-bottom: 1.5rem;
}

.c-photo-detail__video {
  height: 100%;
  width: 100%;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  &.is-visible {
    display: block;
  }
}

.c-photo-detail__icon {
  margin-right: 7px;
}

.c-photo-detail__icon-title {
  font-weight: $font-weights-medium;
}

.c-photo-detail__icon-text-container {
  position: relative;
  margin-bottom: 10px;

  @include media-breakpoint-down(sm) {
    margin-top: 24px;
  }
}

.c-photo-detail__icon-text {
  position: absolute;
  left: 140px;
}

.c-photo-detail__podcast-overlay {
  background-color: black;
  width: 100%    !important;
  height: auto   !important;
  margin-top: 24px;
  position: relative;
  display: none;
  overflow: hidden;
  text-align: center;

  audio {
    width: 94%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
  }

  img {
    filter: blur(10px);
    transform: scale(1.1);
  }

  &.is-visible {
    display: block;
  }

  &:after {
    content: '';
    display: block;
    height: 72px;
    width: 72px;
    background-color: var(--color-primary);
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 100%;
    background-image: url("../img/headphones-solid.svg");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.c-photo-detail__vimeo {
  iframe {
    height: 100%;
    width: 100%;
  }
}

.c-photo-detail__podcast-duration {
  position: absolute;
  padding: 4px 8px;
  height: 30px;
  margin: auto;
  color: white;
  background-color: rgba(28,28,27,0.7);
  border-radius: 4px;
  left: 0;
  right: 0;
  text-align: center;
  max-width: 60px;
  top: 124px;
  bottom: 0;
  z-index: 1;
}
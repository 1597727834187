
.c-person-card{
 justify-content: flex-start !important;
  flex-direction: column !important;
}

.c-person-card__content{
  min-height: 130px;
  padding: 1.11rem 1.11rem;

}
.c-person-card__icon{
  color: var(--color-primary);
  display: block;
  min-width: 28px;
}


.c-person-card__social{
  background-color: var(--color-primary);
  color: white;
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 0.875rem;
  transition: $transition-base;
  &:hover{
    background-color: white;
    color: var(--color-primary);
    transition: $transition-base;
    border: 1px solid var(--color-primary);
    text-decoration: none;
  }
}

.c-person-card-detail{
  display: flex;
  word-break: break-all;
}


.c-copyright__container{
  position: relative;
  cursor: pointer;

  &:hover{
    .c-copyright__content{
      display: block;
    }
  }
  max-width: 740px;

  &.wide{
    max-width: 100%;
  }
}

.c-copyright__content{
  background-color: rgba(28,28,27,0.6);
  font-weight: 600;
  font-size: 0.889rem;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 2rem;
  padding: 0.4rem 1rem;
  color: var(--color-light);

  a{
    color: var(--color-light) !important;
    margin-left: 0.5rem;
    text-decoration: underline;

    &:hover{
      color: var(--color-primary) !important;
    }
  }
  @include media-breakpoint-down(md) {
    font-size: 0.778rem;
    padding: 0.4rem 0.5rem;
    height: auto;
  }
}


.b-program{

  .t-single__info-item-label{
    min-width: 70px;
  }
}
.t-single__content .b-program__title{
  font-size: 2rem;
}
.b-program__subtitle{
  font-weight: 500;
}

.b-program__container{
  border-bottom: 1px solid var(--color-grey-2);
}

.b-program__image-container{
  width: 120px;
}

.b-program__single-image{
  img{
    border-radius: 100%;
  }
}

.b-program__persons{
  a{
    color: var(--color-dark);
    text-decoration: underline;
  }
}

.b-program__part-content{
  transition: height $transition-default;
  max-width: 517px;

  &.limitShow{
    max-height: 0;
    transition: height $transition-default;
    overflow: hidden;
  }
}

.b-program__button-container {
  .b-program__button {
    margin-top: 1rem;
    margin-bottom: .5rem;
    font-size: 0.75rem;
    font-weight: 500;
  }
}

.b-program__button-container{
  &.open{
    .b-program__button{
      margin-top: -2rem;
      margin-bottom: 1.5rem;

    }
  }
}

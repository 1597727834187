.t-guideline-single {

}

.t-guideline-single__info {
   display: flex;
   margin-top: 1.5rem;
   margin-bottom: 1rem;
}

.t-guideline-single__labels {
   margin-right: .75rem;
}

.t-guideline-single__label {
   margin-left: .875rem;
   letter-spacing: -.1px;
}

.t-guideline-single__info-item {
   font-size: 1rem;
   line-height: 1.875rem;
   margin-bottom: .5rem;

   &:last-child {
      margin-bottom: 0;
   }
}

.t-guideline-single__info-values {

}

.t-guideline-single__organisation {

}

.t-guideline-single__evaluation {

}

.t-guideline-single__tabs {
   border-bottom: 1px solid var(--color-grey-3);
   margin-bottom: 2.5rem;

   @include media-breakpoint-down(sm) {
      margin-bottom: .75rem;
   }

   @include media-breakpoint-down(xs) {
      display: flex;
      overflow-x: scroll;
   }
}


.t-guideline-single__tab {
   color: var(--color-grey-5);
   padding: 1rem;
   background: none;
   border: none;
   font-size: .875rem;
   line-height: 1.125rem;
   letter-spacing: -.1px;

   &.is-active {
      color: var(--color-dark);
      border-bottom: .125rem solid var(--color-primary);
   }
}

.t-guideline-single__tab-content {
   display: none;

   @include media-breakpoint-down(sm) {
      margin-top: 1.5rem;
   }

   &.is-active {
      display: block;
   }
}

.t-guideline-single__tab-content--general {
   &.is-active {
      display: flex;

      @include media-breakpoint-down(md) {
         flex-direction: column;
      }
   }
}

.t-guideline-single__general-text {
   @include media-breakpoint-up(lg) {
      margin-right: 3.75rem;
      width: 620px;
   }
}

.t-guideline-single__toc {
   &.is-fixed {
      .t-guideline-single__toc-inner {
         position: fixed;
         top: $header-height;
      }
   }

   @include media-breakpoint-down(md) {
      order: -1;
   }
}

.t-guideline-single__toc-inner {
}

.t-guideline-single__attachment-group {
   margin-bottom: 1.5rem;
}

.t-guideline-single__attachment-group-title {
   font-size: 1rem;
   line-height: 1.25rem;
   font-weight: $font-weights-semi-bold;
   margin-bottom: .5rem;
}

.t-guideline-single__attachment {
   display: block;
}

.t-guideline-single__link-icon {
   color: var(--color-grey-5);
   margin-right: .75rem;
}

.t-guideline-single__attachment-title {
   font-size: 1rem;
   line-height: 1.875rem;
   letter-spacing: -.1px;
   margin-bottom: .25rem;
   text-decoration: underline;
}

.c-hamburger {
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  padding: 0.7rem 1.25rem;

  @include media-breakpoint-down(md) {
    padding: 0.7rem;
    border-radius: 100%;
    margin: 0 0.5rem;
  }

  &:hover {

    .c-hamburger__bar {
      height: 2px;
      background: var(--color-primary);
    }
  }

  &.is-open .c-hamburger__bar {
    &:nth-child(1) {
      top: 9px;
      transform: rotate(45deg);
      height: 2px;
    }
    &:nth-child(2) {
      top: 9px;
      transform: scale(0.2);
      opacity: 0;
    }
    &:nth-child(3) {
      top: 9px;
      height: 2px;
      transform: rotate(-45deg);
    }
  }

}

.c-hamburger__icon {
  height: 21px;
  width: 24px;
  position: relative;
}


$spacing: 7px;
.c-hamburger__bar {
  height: 2px;
  background: var(--color-primary);
  width: 100%;
  position: absolute;
  transition: all 200ms ease-in-out;

  &:nth-child(1) {
    top: $spacing * 0.5;
  }
  &:nth-child(2) {
    top: $spacing * 0.5 + $spacing;
  }
  &:nth-child(3) {
    top: $spacing * 0.5 + $spacing * 2;
  }
}

.b-ad-itempage__wrapper{
    left: 50%;
    transform: translateX(-50%);
    width:100vw;
}

.b-ad-itempage__multiblock-col{
    padding:0 15px!important;
    max-width: 300px;
    @include media-breakpoint-down(md) {
        margin-bottom: 15px;
    }
}

.b-ad-itempage__multiblock{
    height:1600px;
    @include media-breakpoint-up(sm) {
        height: 800px;
    }
    @include media-breakpoint-up(lg) {
        height: 395px;
    }
    &.has-title{
        height:1655px;
        @include media-breakpoint-up(sm) {
            height: 855px;
        }
        @include media-breakpoint-up(lg) {
            height: 450px;
        }
    }
}

.b-ad-itempage__multiblock-title{
    margin-bottom: 1rem;
}

.b-three-columns {

}

.b-three-columns__column-title--mobile, .b-three-columns__column-image--mobile {
  display: block;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.b-three-columns__column-title--desktop, .b-three-columns__column-image--desktop {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.b-three-columns__column-video {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.b-three-columns__column-video iframe {

  @include media-breakpoint-up(md) {
    width: 370px;
    height: 208px;
  }
}




.c-calendar-card {

}

.c-calendar-card__date {
  background-color: var(--color-calendar-card-background);
  color: var(--color-dark, #000);
  text-align: center;
  padding: 1rem;
  height: 144px;

  @include media-breakpoint-down(md) {
    width: 110px;
  }
}

.c-item-card__location,
.c-item-card__metadate {
  font-size: 0.75rem;
}

.c-calendar-card__date-day {
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

.c-calendar-card__date-date {
  font-weight: bold;
  font-size: 1.667rem;
}

.c-calendar-card__date--pinned {
  width: 53%;
  height: 100%;
  text-align: center;
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .c-calendar-card__date-day {
    font-size: 1.5rem;
  }

  .c-calendar-card__date-date {
    font-size: 4rem;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    height: 120px;
    padding: 1rem;
    flex-shrink: 0;

    .c-calendar-card__date-day {
      font-size: 1rem;
    }

    .c-calendar-card__date-date {
      font-size: 2rem;
    }
  }
}

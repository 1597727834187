.b-wp-core-list {
  a {
    text-decoration: underline;
  }

  ol,
  ul {
    list-style-type: none;
    margin-left: -1rem;
    position: relative;
  }

  ol > li,
  ol > .b-wp-core-list-item > li {
    counter-increment: customlistcounter;
    margin-bottom: 0.4rem;
    padding-left: 1rem;
  }

  ol > li::before,
  ol > .b-wp-core-list-item > li::before {
    content: counter(customlistcounter) ") ";
    position: absolute;
    left: 1.5rem;
  }

  ol:first-child {
    counter-reset: customlistcounter;
  }

  ul > li,
  ul > .b-wp-core-list-item > li {
    margin-bottom: 0.4rem;
    padding-left: 1rem;
  }

  ul > li::before,
  ul > .b-wp-core-list-item > li::before {
    content: "•";
    position: absolute;
    left: 1.5rem;
  }
}

.b-wp-core-paragraph + .b-wp-core-list {
  margin-top: -0.8rem;
}

.learndash-wrapper {
  .b-wp-core-list-item li {
    font-size: 0.875rem;

    &::marker {
      display: none;
      content: "";
    }
  }
}

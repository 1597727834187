.c-comment__container{
  margin-bottom: 1.5rem;

  .c-comment__container{
    margin-bottom: 0;
  }
}

.c-comment{
  background-color: var(--color-grey-1);
  padding: 1.5rem;
  color: var(--color-dark);
  border-radius: $border-radius;

  .c-comment{
    padding-bottom: 0;
  }
  @include media-breakpoint-down(md) {
    padding: 1rem;
  }
}


.c-comment__list{
  margin-top: 1.5rem;
  border-top: 1px solid var(--color-grey-4);
  padding: 0 1rem;

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  .comment-form-url{
    display: none;
  }
}

.c-comment__avatar{
  border-radius: 100%;
}

.c-comment__author{
  font-weight: 600;
}

.c-comment__date{
  opacity: 50%;
  font-size: 0.875rem;
  letter-spacing: -0.1px;
}

.c-comment__content{
  line-height: 30px;
}

.c-comment__reply{
  .comment-reply-link{
    @extend .c-button--ghost;
    border-radius: $border-radius;
  }
}

.c-comment__like{
  margin-left: auto;
  i{
    font-size: 2rem;
    color: var(--color-primary);
    margin-left: 1rem;
    cursor: pointer;

    &.is_clicked{
      font-weight: bold;
    }
  }
}

.c-comment__owner{
  margin-left: 1rem;
  background-color:var(--color-grey-5);
  color:var(--color-grey-2);
  font-size: 0.75rem;
  padding:0.5rem;
  border-radius: 0.25rem;
  line-height:0.9375rem;
}
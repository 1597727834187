$headerheight: 80px;
$subheaderheight: 36px;
$adminbarheight: 32px;

.s-header {
  box-shadow: 0 2px 8px 0 rgb(28 28 27 / 7%);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  //overflow-x: hidden;
  height: auto;
  z-index: 10;
  background-color: var(--color-light);

  //background-color: var(--color-background-24);
}

.admin-bar {
  .s-header {
    top: $adminbarheight;
  }
}

.s-header__submenu {
  //background-color: var(--color-tertiary-36);
  height: $subheaderheight;

  @include media-breakpoint-down(md) {
    height: auto;
    padding-bottom: 1rem;
  }
}

.s-header__mainmenu {
  height: $header-height;
  background-color: rgb(255 255 255 / 5%);
}

.s-header__mainmenu-container {
  height: $headerheight;

  @include media-breakpoint-down(md) {
    height: 100%;
  }
}

.s-header__mainmenu-container--pullright {
  position: relative;
  right: -0.9125rem;
}

.s-header__mobile {
  display: none;
  height: calc(100vh - #{$headerheight});
  overflow-y: scroll;
  position: relative;
  padding: 1rem 5rem;

  .container {
    width: 50%;
    float: left;

    @include media-breakpoint-down(xs) {
      width: auto;
      float: unset;
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 1rem;
  }
}

.s-header__mobile-container {
  overflow-y: scroll;
  height: 100%;
  margin-bottom: 1rem;
}

.s-header__search {
  font-size: 1.3rem;
  background-color: var(--color-primary);
  width: 56px;
  height: 56px;
  border: none;
  border-radius: 100%;
  color: var(--color-light);
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(md) {
    color: var(--color-primary);
    background-color: transparent;
    padding: 0;
    width: auto;
    height: auto;
  }

  &:hover {
    box-shadow: var(--shadow-default);
  }

  &:focus {
    box-shadow: var(--shadow-inset);
    background-color: var(--color-primary-darker);
    outline: none;
  }
}

.menu-is-open {
  .s-header {
    overflow-x: initial;
  }
}

.b-splitter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.b-splitter__placeholder {
  width: 90%;
  border-bottom: 2px solid #777;
  margin: 1rem 0;

  h6 {
    margin: 0;
    text-align: center;
    color: rgb(33, 37, 41);
  }
}

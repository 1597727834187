

.c-color__primary{
  color: var(--color-primary);
  svg{
    path{
      fill: currentColor;
    }
  }
}

.c-color__secondary{
  color: var(--color-secondary);
  svg{
    path{
      fill: currentColor;
    }
  }
}

.c-color__tertiary{
  color: var(--color-tertiary);
  svg{
    path{
      fill: currentColor;
    }
  }
}

.t-svg--currentColor {
  svg{
    path{
      fill: currentColor;
    }
  }
  
}


.c-color__black{
  color: var(--color-dark);
  svg{
    path{
      fill: currentColor;
    }
  }
}

.c-color__white{
  color: var(--color-light);
  svg{
    path{
      fill: currentColor;
    }
  }
}

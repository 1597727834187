
$closed-block-height : 600px;

.b-comments__container{
  transition: height $transition-default;

  &.limitShow{
    max-height: $closed-block-height;
    overflow: hidden;
    transition: height $transition-default;
  }
}

.b-comments__comments-container{
  list-style-type: none;
  padding-inline-start: 0;

  .comment-respond{
    margin-top: -1.5rem;
    background-color: var(--color-grey-1);
    padding: 1.5rem;
    margin-bottom: 1.5rem;

    #cancel-comment-reply-link{
      padding-left: 1rem;
    }
    .logged-in-as{
      display: none;
    }

    .comment-form-url{
      display: none !important;
    }
  }
}



.b-comments__comments-form{
  .logged-in-as{
    display: none;
  }

  .comment-form-url{
    display: none;
  }


}

.b-comments__button-container{
  margin-top: -$closed-block-height;
  display: none;
  height: 50px;
  background: none;
  box-shadow: unset;

  position: relative;
  text-align: center;
  width: 100%;
  &.show{
    display: block;

  }

  &.open{
    background: linear-gradient(0deg, #FFFFFF 0.08%, rgba(255,255,255,0) 100%);
    height: $closed-block-height;
    z-index: 1;
    box-shadow: inset 0 -1px 0 0 #E6E6E6;
    .b-comments__button{
      margin-top:  calc(#{$closed-block-height} - 20px);
    }
  }
}

.b-comments__button{
  margin-top: $closed-block-height;
  text-align: center;
}

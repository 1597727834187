.b-videos {

  a {
    text-decoration: none;
  }

  @include media-breakpoint-down(xs){
    margin: 25px 0;
  }
}

.b-videos__title {
  margin-bottom: 24px;
}

.b-videos__featured-video-title {
  font-size: 1.375rem;
  line-height: 1.27;
  color: var(--color-dark);
  margin-bottom: 0.5rem;
  margin-top: 1rem;

  @include media-breakpoint-down(xs){
    max-width: 500px;
    font-size: 1.1rem;
  }
}

.b-videos__featured-video-text {
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: var(--color-dark);

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

  @include media-breakpoint-down(xs){
    max-width: 500px;
    margin-bottom: 40px;
    -webkit-line-clamp: 4; /* number of lines to show */
  }
}

.b-videos__other-video-grid {
  @include media-breakpoint-down(xs){
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
}

.b-video__line {
  margin: 1rem 0;

  @include media-breakpoint-down(xs){
    display: none;
  }
}

.b-videos__featured-video {
  height: 528px;
  width: 100%;

  @include media-breakpoint-down(xs){
    max-width: 500px;
    max-height: 400px;
  }

  img{
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.b-videos__other-video {
  width: 100%;
  height: 197px;

  @include media-breakpoint-down(xs){
    max-height: 150px;
  }

  img{
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}



.b-videos__other-video-title {
  font-size: 1rem;
  line-height: 1.18;
  color: var(--color-dark);
  margin-top: 0.75rem;

  @include media-breakpoint-down(xs){
    max-width: 200px;
    font-size: 0.8rem;
  }
}

.b-video--button-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;

  @include media-breakpoint-down(xs){
    position: relative;
    margin-top: 40px;
  }
}

.b-videos__video-blur {
  overflow:hidden;
  position: relative;

  &:after {
    content: '';
    display: block;
    height: 72px;
    width: 72px;
    background-color: var(--color-primary);
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 100%;
    background-image: url("../img/play-solid.svg");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
  }

  img{
    transform: scale(1.1);
    filter: blur(3px);
  }
}

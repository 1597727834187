$accordion-padding: 1rem;
$accordion-padding-mob: 0.625rem;
$button-size: 0.75rem;

.c-accordion {
  position: relative;
  border-radius: 0.5rem;

  &.is-open {
    .c-accordion__button {
      &::after {
        transform: rotate(-180deg) translateY(-50%);
      }

      &::before {
        transform: rotate(0) translateY(-50%);
        opacity: 0;
      }
    }
  }
}

.c-accordion__inner {
  position: relative;
  overflow: hidden;
}

.c-accordion__button {
  position: relative;
  z-index: 1;
  width: 100%;
  border: 0;
  background-color: transparent;
  text-align: left;
  padding: $accordion-padding-mob $accordion-padding-mob + $button-size $accordion-padding-mob $accordion-padding-mob;

  @include media-breakpoint-up(lg) {
    padding: $accordion-padding $accordion-padding + $button-size $accordion-padding $accordion-padding;
  }

  &:hover {
    cursor: pointer;
  }
}

.c-accordion__title {
  font-size: 1rem;
  color: var(--color-dark);
  padding-right: $accordion-padding-mob;
  margin: 0;
  pointer-events: none;

  @include media-breakpoint-up(lg) {
    font-size: 1.125rem;
    padding-right: $accordion-padding;
  }
}

.c-accordion__content {
  display: none;
  will-change: height, padding, margin;
  font-size: 1rem;
  padding: 0 $accordion-padding-mob;

  @include media-breakpoint-up(lg) {
    font-size: 1.125rem;
    padding: 0 $accordion-padding;
  }
}

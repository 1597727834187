.c-video-detail__video {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;

  &.is-visible {
    display: block;
  }
}

.c-video-detail__icon {
  min-width: 20px;
  margin-right: 7px;
}

.c-video-detail__icon-title {
  font-weight: $font-weights-medium;
  display: inline-block;
  min-width: 110px;
  max-width: 110px;
}

.c-video-detail__icon-text-container {
  display: flex;
  margin-bottom: 10px;

  @include media-breakpoint-down(sm) {
    margin-top: 0.6rem;
  }
}

.c-video-detail__icon-text {
  display: inline-block;
}

.c-video-detail__video-overlay {
  width: 100%;
  margin-top: 24px;
  position: relative;
  background-color: var(--color-primary);
  overflow: hidden;
  margin-bottom: 24px;

  @include media-breakpoint-down(xs){
    height: 200px;
  }

  picture{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.is-hidden{
    background-color: transparent;
    img {
      visibility: hidden;
    }
  }

  &:after {
    content: '\f04b';
    font-family: $font-awesome;
    font-weight: 900;
    font-size: 28px;
    text-align: center;
    line-height: 2.57;
    color:var(--color-light);
    display: block;
    height: 72px;
    width: 72px;
    background-color: var(--color-primary);
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 100%;
  }
}

.c-video-detail__vimeo {
  iframe {
    height: 100%;
    width: 100%;
  }
}

.c-video-detail__video-duration {
  font-size: 13px;
  position: absolute;
  padding: 4px 8px;
  height: 30px;
  margin: auto;
  color: white;
  background-color: rgba(28,28,27,0.7);
  border-radius: 4px;
  left: 0;
  right: 0;
  text-align: center;
  max-width: 60px;
  top: 124px;
  bottom: 0;
  z-index: 1;
}
.b-grid-list {

  a {
    text-decoration: none;
  }
}

.b-grid-list__featured-media-item-thumbnail {
  height: 588px;
  background-color: var(--color-secondary);

  img{
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  @include media-breakpoint-down(xs){
    width: 100%;
    height: 250px;
  }
}

.b-grid-list__title {
  margin-bottom: 12px;
}

.b-grid-list__intro-text {
  margin-bottom: 24px;
  max-width: 800px;
}

.b-grid-list__featured-media-item-title {
  font-size: 1.375rem;
  line-height: 1.27;
  color: var(--color-dark);
  margin-bottom: 0.5rem;
  margin-top: 1rem;

  @include media-breakpoint-down(xs){
    max-width: 500px;
    font-size: 1.1rem;
  }
}

.b-grid-list__featured-media-item-text {
  font-size: 0.9rem;
  line-height: 0.9375rem;
  color: var(--color-dark);

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

  @include media-breakpoint-down(xs){
    margin-bottom: 30px;
    -webkit-line-clamp: 4; /* number of lines to show */
  }
}

.b-grid-list__other-media-items-grid {
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(120px, 1fr));
  height: 578px;
  grid-row-gap: 20px;
  align-content: stretch;

  @include media-breakpoint-down(xs) {
    grid-template-rows: 120px;
    height: auto;
  }
}

.b-grid-list__other-media-items-container {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
  grid-column-gap: 10px;
  height: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
}


.b-grid-list__other-media-item {
  height: 100%;
  width: 120px;
  background-color: var(--color-secondary);
  display: inline-block;

  img{
    object-fit: cover;
    height: 100%;
    width: 100%;
  }


  @include media-breakpoint-down(xs) {
    height: 120px;
  }
}

.b-grid-list__other-media-item-title {
  font-size: 1rem;
  line-height: 1.18;
  color: var(--color-dark);
  margin-top: 0.1rem;
  max-width: 100%;
}

.b-grid-list__other-media-item-text {
  font-size: 0.75rem;
  line-height: 15px;
  max-width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.b-grid-list--button-container {
  text-align: center;
  margin-top: 35px;
}

.b-grid-list__blur {
  overflow:hidden;
  position: relative;

  &:after {
    content: '';
    display: block;
    height: 72px;
    width: 72px;
    background-color: var(--color-primary);
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 100%;
    background-image: url("../img/headphones-solid.svg");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
  }

  img{
    transform: scale(1.1);
    filter: blur(3px);
  }
}

.b-grid-list__line {
  margin-top: 12px;
}


.b-grid-list__blur--video {
  &:after {
    background-image: url("../img/play-solid.svg");
  }
}

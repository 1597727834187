

.c-card-overlay{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(28,28,27,0);
}

.c-card-overlay__icon{
  background: var(--color-primary);
  color: var(--color-light);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  font-size: 1.5rem;
  margin: 0.5rem 0;
  @include media-breakpoint-down(md) {
    font-size: 1rem;
    width: 50px;
    height: 50px;
  }
}

.c-card-overlay__duration{
  font-size: 0.8125rem;
  padding: 4px 8px;
  color: var(--color-light);
  background-color: rgba(28, 28, 27, 0.7);
  border-radius: $border-radius;
  text-align: center;
  margin: 0.5rem 0;
}



//.t-kiosk-single__intro{
//  font-weight: 600;
//  font-size: 1.111rem;
//  line-height: 1.778rem;
//  letter-spacing: -0.2px;
//
//  @include media-breakpoint-down(sm) {
//    font-size: 1rem;
//  }
//}


.t-kiosk-single__top-right{
  width: 280px;
  flex-shrink: 0;
}

.t-kiosk-highlighted__image{
  img{
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
  }
}
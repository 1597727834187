// Global layout styles

body {
  background: var(--color-background-24);
  overflow-wrap: break-word;
  hyphens: auto;

  @if $context == 'editor' {
    background: white;
  }

  &.menu-is-open {
    background-color: rgba(#1c1c1b, 0.6);
    z-index: 1;
    position: fixed;
    overflow: hidden;
    width: 100%;
  }
}

img {
  max-width: 100%;
}

// Default link color transition
a {
  color: var(--color-dark);
  transition: color $transition-default;

  &:hover {
    color: var(--color-primary);
  }

  @include in_editor {
    text-decoration: underline;
  }
}

// Add margin for targeted anchor links
[id] {
  scroll-margin-top: calc(#{$header-height} + 2ex);
}

div[class^="b-"],
div[class*=" b-"] {
  p a {
    text-decoration: underline;
  }
}

.editor-visual-editor {
  background:#000!important;
}

.b-highlighted-cards {

}

.b-highlighted-cards__title {
  margin-bottom: 24px;
}

.b-highlighted-cards-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 20.75rem);
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;

  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media-breakpoint-down(xs) {
    display: block;
  }
}

.b-highlighted-cards-card {
  width: 100%;
  height: 20.75rem;
  grid-area: card;
  background-color: white;

  @include media-breakpoint-down(xs) {
    height: 200px;
    margin-bottom: 24px;
  }

  &:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;

    @include media-breakpoint-down(sm) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }

  &:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;

    @include media-breakpoint-down(sm) {
      grid-column-start: 3;
      grid-column-end: 5;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }

  &:nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;

    @include media-breakpoint-down(sm) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 4;
    }
  }

  &:nth-child(5) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;

    @include media-breakpoint-down(sm) {
      grid-column-start: 3;
      grid-column-end: 5;
      grid-row-start: 3;
      grid-row-end: 4;
    }
  }

  &:nth-child(6) {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;

    @include media-breakpoint-down(sm) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 4;
      grid-row-end: 5;
    }
  }

}

.b-highlighted-cards-highlighted-card {
  width: 100%;
  height: 100%;
  grid-area: highlighted-card;
  background-color: white;
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;


  @include media-breakpoint-down(sm) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  @include media-breakpoint-down(xs) {
    height: 300px;
    margin-bottom: 24px;
  }
}


.c-dossier-card {
  @extend .d-flex, .flex-row-reverse, .flex-lg-column;

  border-radius: $border-radius;
  box-shadow: var(--shadow-lighter);
  transition: $transition-default;
  display: block;
  position: relative;
  overflow: hidden;

  &:hover,
  :active {
    color: var(--color-dark);
    text-decoration: none;

    .c-item-card__title {
      color: var(--color-secondary);
      text-decoration: underline;
    }

    .c-dossier-card__icon {
      color: var(--color-primary);
    }
  }

  &:hover {
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 16%);
  }

  &:active {
    background: var(--color-grey-2);
  }
}

.c-dossier-card__top {
  min-height: 100px;
  background: var(--color-card-background);

  @include media-breakpoint-down(md) {
    max-width: calc(100% - 110px);
    width: 100%;

    &.wider {
      max-width: 100%;
      width: 100%;
    }
  }
}

.c-dossier-card__down {
  background-color: var(--color-tertiary-36);
  width: 100%;
  height: 144px;

  @include media-breakpoint-down(md) {
    width: 110px;
    min-height: 144px;
    max-height: 144px;
    height: 100%;
  }
}

.c-dossier-card__title {
  font-size: 1rem;
}

.c-dossier-card__icon {
  font-size: 2.9rem;
  max-height: 120px;
  aspect-ratio: 1 / 1;
}

.c-dossier-card__icon-image {
  max-height: 100%;

  img {
    object-fit: contain;
  }
}

// Reset conflicts for cookie plugin
.cli-tab-header {
  .cli-switch .cli-slider:before {
    margin: auto;
  }

  input[type=radio]:checked + label, input[type=checkbox]:checked + label {
    position: absolute;
    width: auto;
  }

  input[type=radio]:checked + label:after, input[type=checkbox]:checked + label:after {
    content: "\f00c";
    color: #000;
    position: absolute;
    display: inline-block;
    text-align: right;
    cursor: pointer;
    left: auto;
    top: auto;
    font-size: 12px;
    font-weight: normal;
  }
}
body .cli-tab-footer .wt-cli-privacy-save-btn,
body .cli-tab-footer .wt-cli-privacy-accept-btn
{
  background-color: #1da000;
}

.b-observation{
  position: relative;

  .b-authors__container{
    margin: 0 1rem;
  }
}

.b-observation__content{
  max-height: 100%;
  transition: height $transition-default;
  .b-authors__single-content{
    z-index: 3;
  }

  &.limitShow{
    transition: height $transition-default;
    max-height: 120px;
    overflow: hidden;
  }

}


.b-observation__button-container{
  margin-top: -150px;
  height: 150px;
  background: linear-gradient(0deg, #FFFFFF 0.08%, rgba(255,255,255,0) 100%);
  box-shadow: inset 0 -1px 0 0 #E6E6E6;
  position: relative;
  z-index: 2;
  text-align: center;

  &.open{
    background: none;
    height: 170px;
    .b-observation__button{
      margin-top: 150px;
    }
  }
}

.b-observation__button{
 margin-top: 130px;
  text-align: center;
}

//@see ~bootstrap/scss/_variables.css

$spacer: 1rem;

//Spacer classes
//e.g. mb-1 = margin-bottom spacer*.25 = 4px
// or  mb-1s = margin-bottom 1 spacer = 16px
// or  mb-2s = margin-bottom 2 spacers = 32px
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 4.5),
  7: ($spacer * 6),
  8: ($spacer * 9),
  9: ($spacer * 12),
  1s: ($spacer * 1),
  2s: ($spacer * 2),
  3s: ($spacer * 3),
  4s: ($spacer * 4),
  5s: ($spacer * 5),
);
//@see ~bootstrap/scss/_variables.css

// Bootstrap grid
// Change this if you know what you're doing
// Bootstrap uses these for example for col-md-* sizing
$bp-xs: 0;
$bp-sm: 768px;
$bp-md: 992px;
$bp-lg: 1200px;
$bp-xl: 1500px;
$gutter: 16px;

$grid-breakpoints: (
  xs: $bp-xs,
  sm: $bp-sm,
  md: $bp-md,
  lg: $bp-lg,
  xl: $bp-xl,
);

$container-max-widths: (
  sm: $bp-sm - 36px,
  md: $bp-md - 48px,
  lg: $bp-lg - 32px,
  xl: $bp-xl - 60px,
);

.container--small{
  max-width: 1200px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
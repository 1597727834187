

.c-science-card__content{
  span, div{
    font-size: 0.75rem;
    line-height: 0.9375rem;
  }
}
.c-science-card__authors{
  padding: 0.3rem 0;
}

.c-science-card__magazine{
  font-style: italic;
  display: inline-block;
  padding: 0.3rem 0;
}
.c-science-card__date{
  display: block;
  padding: 0.3rem 0;
}

.c-science-card__doi{

}


.t-science__highlighted-image{
  img{
    object-fit: contain;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
    width: 282px;
  }
}

.t-science__doi{
  word-break: break-word;
}

.t-science__content{
    h2{
      padding-bottom: 1rem;
      box-shadow: inset 0 -1px 0 0 #E6E6E6;
      font-weight: 500;
    }
}

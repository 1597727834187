.c-linked-person-card{
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: var(--shadow-lighter);
  transition: 100ms ease-in-out;
  height: 100%;
  text-decoration: none !important;
}

.c-linked-person-card--nolink:hover{
  box-shadow: var(--shadow-lighter)!important;
}

.c-linked-person-card--nolink:hover{
  box-shadow: var(--shadow-lighter)!important;
}

.c-linked-person-card--nolink:active{
  background: none!important;
}

.c-linked-person-card-detail{
  font-size: 14px;
}

.c-linked-person-card__details{
  font-size: 0.875rem;
}

.c-linked-person-card--popup{
  display: block!important;
  box-shadow: none!important;
  width:100%;
}

.c-linked-person-card--popup:hover{
  box-shadow: none!important;
}



.t-archive__intro {
  margin-bottom: 2rem;
}

.t-archive__title {
  font-size: 2rem;
}

.t-archive__subtitle {
  font-weight: normal;
  font-size: 1.25rem;
}

.t-archive__intro-image {
  object-fit: cover;
}

.t-archive__intro-video {
  > * {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;

    @include media-breakpoint-down(xs) {
      height: 250px;
    }
  }
}

.t-archive__post-container {
  &.is_loading {
    .c-item-card {
      display: none !important;
    }
  }
}

.t-archive__pagination-container {
  &.is_loading {
    opacity: .4;
  }
}

.t-archive--tax {
  h2 {
    font-size: 1.875rem;
  }
}

.t-archive--faq {
  scroll-behavior: smooth;
  h3 {
    font-size: 1.375rem;
    font-weight: 500;
  }
}

.t-archive__faq{
  background-color: var(--color-tertiary-12);
  color: var(--color-primary);
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  @include in_editor() {
    background-color: $gray-700;
  }
}

.t-archive__faq-link{
  font-size: 0.75rem;
  text-transform: uppercase;
}

.t-archive__faq-link + .t-archive__faq-link{
  margin-left: 0;
}

.t-archive__faq-top-button{
  border-radius: 4px;
  &.fix{
    position:fixed;
    top: 130px;
  }
}

.t-archive__question{
  position: relative;
  font-size: 1rem;
  font-weight: 600;

  @include media-breakpoint-down(md) {
    padding-right: 1.5rem;
  }

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: '\f078';
    font-family: $font-awesome;
    font-size: 1rem;
    font-weight: 300;
    position: absolute;
    right: 0;
    top: 50%;
    transform-origin: center;
    transform: translateY(-50%);
    transition: transform $transition-default;

    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }

  &.is-open {
    &:after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.t-archive__answer{
  font-size: 1rem;
  display: none;
  margin-top: 0.5rem;
  color: var(--color-dark);

  a{
    color: var(--color-primary);
    text-decoration: underline;
  }
}

.t-single--organisation{
  @include media-breakpoint-down(sm) {
    .c-social-share__container{
      margin-top: -2rem;
    }
  }
}


.t-organisation-single__top-left{
  max-width: 665px;

  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}

.t-organisation-single__info-card{
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--color-card-background);
}
.t-organisation-single__info-card-details{
  font-size: 0.875rem;
  word-break: break-all;
}

.t-organisation-single__info-card-icon{
  color: var(--color-primary);
  display: inline-block;
  min-width: 25px;
}

.t-organisation-single__categories {
  font-size: 0.83rem;
  line-height: 1.3rem;
  color: #848480;
  margin-top: 1.2rem;
  max-width: 90%;

  i {
    margin-right: 8px;
    margin-left: 1px;
    font-size: 13px;
  }

  &--organisation-card {
    margin-bottom: 0;
  }
}

.t-organisation-single__categories-line:last-child {
  display: none;
}

.t-organisation-single__categories-comma:last-child {
  display: none;
}

.t-organisation-single__profile-button {
  white-space: nowrap;
}

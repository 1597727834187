
.b-logos{


  .tns-outer{
    position: relative;
  }

  .b-logos__slider{
    position: relative;
  }

  .b-logos__nav{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;

    span{
      display: flex;
      align-items: center;
      height: 100%;
      background-color: var(--color-light);
      border: none;
      z-index: 2;
      width: 20px;
      pointer-events: auto;
    }

    .b-logos__nav-left{
      &:before{
        position: absolute;
        content: '';
        height: 100%;
        left: 20px;
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
        width: 100px;
        z-index: 1;
        transform: scaleX(-1);
      }
    }

    .b-logos__nav-right{
      &:after{
        position: absolute;
        content: '';
        height: 100%;
        right: 20px;
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
        width: 100px;
        z-index: 1;
      }
    }


  }
}
.b-logos__image-container {
  height: 80px;
  position: relative;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    max-width: 100px;
    max-height: 75px;
    @include in_editor() {
      position: relative;
    }
  }

  &.tns-item{
    vertical-align: unset;
  }
}

.b-logos__position--center{
  text-align: center;
}

.b-logos__position--right{
  text-align: right;
}
.sfwd-courses-template-default .learndash-wrapper {
  & .ld-progress.ld-progress-inline {
    padding: 0;
    gap: 6px 20px;
    flex-direction: row;
    & p {
      display: none;
    }
    & .ld-progress-bar {
      flex: 1;
      height: 16px;
      min-width: 140px;
      background: var(--color-tertiary-24);

      & > .ld-progress-bar-percentage {
        height: 16px;
        border-radius: 16px;
        background: var(--color-primary);
      }
    }
  }
}
.learndash-wrapper
.ld-progress
.ld-progress-heading
.ld-progress-stats
.ld-progress-percentage {
  color: var(--color-primary);
  margin-right: 20px;
  font-size: 14px;
}
.learndash-wrapper
.ld-progress
.ld-progress-heading
.ld-progress-stats
.ld-progress-steps {
  font-size: 14px;
}

.learndash-wrapper .ld-expand-button.ld-primary-background {
  background-color: var(--color-tertiary-24);
  color: var(--color-primary);
  border-radius: 4px;
}

.learndash-wrapper .ld-expand-button.ld-button-alternate {
  color: var(--color-primary);
  hyphens: manual;
  & .ld-icon {
    background: var(--color-primary);
  }
}

.learndash-wrapper .ld-table-list .ld-table-list-header {
  background: var(--color-primary);
}

.learndash-wrapper
.ld-item-list
.ld-item-list-item
.ld-item-list-item-expanded:before {
  display: none;
}

.learndash-wrapper .ld-item-lesson-item {
  & .ld-item-title {
    font-weight: 700;
  }
  & .ld-item-components,
  & .ld-table-list-item-quiz .ld-item-title {
    font-weight: 400 !important;
  }
  & .ld-table-list .ld-table-list-item-preview {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
}

.learndash-wrapper .ld-item-list.ld-course-list .ld-section-heading h2,
.learndash-wrapper .ld-item-list.ld-lesson-list .ld-section-heading h2 {
  font-size: 32px;
}

.learndash-wrapper .ld-item-list.ld-course-list .ld-lesson-section-heading,
.learndash-wrapper .ld-item-list.ld-lesson-list .ld-lesson-section-heading {
  font-size: 24px;
}

.c-ldtabs {
  &__tabbar {
    display: flex;
    border-bottom: 1px solid gray;
    column-gap: 24px;
    margin-top: 20px;
  }
  &__tab {
    padding: 12px 8px 10px;
    border: 0;
    border-bottom: 2px solid transparent;
    background: transparent;
    color: var(--color-grey-5);
    font-weight: 500;

    &.is-active {
      color: var(--color-primary);
      border-bottom-color: var(--color-primary);
    }
    &:hover,
    &:focus {
      color: var(--color-primary-darker);
      border-bottom-color: var(--color-primary-darker);
    }
  }
  &__panel:not(.is-active) {
    display: none;
  }
}

.c-sfwd-detail {
  &__course-details {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
    margin-bottom: 20px;

    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 500;
    }

    & strong {
      font-weight: 500;
    }

    & i {
      width: 20px;
      height: auto;
    }
  }
}

.learndash-wrapper .ld-item-list .ld-item-list-item .ld-item-list-item-preview {
  padding: 16px;
}
.learndash-wrapper .ld-table-list .ld-table-list-items {
  padding: 0 16px;
}

.learndash-wrapper .ld-item-list.ld-lesson-list .ld-topic-list {
  & .ld-table-list-header {
    padding: 12px 16px 12px 48px;
    & > .ld-table-list-title > .ld-item-icon {
      display: none;
    }
  }
  & .ld-table-list-item {
    margin: 0;
  }
  &.ld-table-list .ld-table-list-item .ld-table-list-item-preview {
    align-items: center;
  }
}

.learndash-wrapper .ld-status-icon {
  flex-basis: 20px;
  width: 20px;
  height: 20px;
}

.learndash-wrapper .ld-status-incomplete.ld-status-icon,
.learndash-wrapper .ld-status-in-progress {
  border-width: 2px;
}

.learndash-wrapper
.ld-topic-list.ld-table-list
.ld-table-list-item
.ld-table-list-item-preview
.ld-status-icon,
.learndash-wrapper
.ld-item-list
.ld-item-list-item
.ld-item-list-item-preview
.ld-status-icon {
  margin-right: 12px;
}

.learndash-wrapper
.ld-topic-list.ld-table-list
.ld-table-list-lesson-details
.ld-lesson-list-steps {
  font-size: 14px;
}

.learndash-wrapper .ld-item-list .ld-item-list-item {
  border-width: 1px;
}

.learndash-wrapper .ld-status-icon.ld-status-complete .ld-icon {
  font-size: 0.7em;
}

.learndash-wrapper .ld-status-complete {
  background-color: var(--color-primary);
}

.learndash-wrapper .ld-status-in-progress {
  border-color: var(--color-primary) #e2e7ed #e2e7ed var(--color-primary);
}

.learndash-wrapper .ld-status-icon.ld-quiz-complete {
  color: var(--color-primary);
}

.learndash-wrapper #btn-join,
.learndash-wrapper .btn-join {
  background-color: var(--color-primary);
  border-radius: 4px;

  &:hover,
  &:focus {
    background-color: var(--color-primary-darker);
  }
}

.learndash-wrapper .ld-alert-success {
  background-color: var(--color-tertiary-24);
  padding: 1rem;
  border-radius: 4px;
  border-color: var(--color-primary);
  min-height: unset;

  .ld-button {
    background-color: var(--color-primary);
    white-space: nowrap;
    color: var(--color-light);
    border-radius: 4px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .ld-alert-icon{
    display: none;
  }
}

.learndash-wrapper{
  .ld-progress{
    .ld-progress-steps{
      display: none;
    }
  }
}
.learndash-wrapper{
  .learndash-course-student-message{
    display: flex;
    .learndash-widget{
      flex-grow: 2;
    }
  }
  p{
    margin-bottom: 0;
    font-size: 14px;
  }
}

.learndash-wrapper{
  .ld-course-resume, .btn-join{
    background-color: var(--color-tertiary-24)!important;
    border-radius: 4px!important;
    font-size: 1rem!important;
    color: var(--color-primary)!important;
    transition: $transition-default!important;
    padding: 0.6rem 1rem!important;
    display: inline-block!important;
    font-weight: 600!important;
    line-height: 1.5!important;
    border: 0!important;
    cursor: pointer!important;

    &:hover {
      text-decoration: none!important;
      box-shadow: var(--shadow-default)!important;
    }

    &:focus {
      text-decoration: none!important;
      color: var(--color-light)!important;
      box-shadow: var(--shadow-inset)!important;
      background-color: var(--color-primary-darker)!important;
      outline: none!important;
    }
  }
}

.c-toc {
   padding: 1.25rem;
   border-radius: $border-radius;
   box-shadow: 0 0.125rem 1rem 0 rgba(0, 0, 0, .08);
   width: 282px;
   background-color: white;
   z-index: 1;
   position: relative;
   @include media-breakpoint-down(sm) {
      width: 100%;
   }
}

.c-toc__title {
   font-size: 1.25rem;
   line-height: 1.5rem;
   font-weight: $font-weights-semi-bold;
   position: relative;
}

.c-toc__link-container {
   position: relative;
   padding: .5rem 0;
   border-bottom: 1px solid var(--color-grey-3);
}

.c-toc__link {
   display: block;
   font-size: 1rem;
   line-height: 1.875rem;
   letter-spacing: -.09px;
   transition: color $transition-default;
   color: var(--color-primary);
   text-decoration: underline;
   margin-right: 2rem;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;

   &.is-active {
      text-decoration: none;
      color: var(--color-dark);
   }

   &:hover {
      color: var(--color-dark);
   }
}

.c-toc__link--child {
   font-size: .875rem;
   line-height: 1.5rem;
   text-decoration: none;
   margin-left: 1rem;
   border-bottom: none;
   padding: .25rem 0;
}

.c-toc__child-toggle {
   background: none;
   border: none;
   position: absolute;
   right: 0;
   top: 50%;
   width: 1.5rem;
   height: 100%;
   padding: 0;
   transition: transform 250ms $transition-default-ease;
   transform: translate(-50%, -50%);


   &.is-active {
      transform: translate(-50%, -50%) rotate(180deg);
      &:after {

      }
   }
}

.c-toc__children {
   display: none;
}

.c-toc__mobile-toggle{
   @extend .c-toc__child-toggle;
   display: none;
   @include media-breakpoint-down(md) {
      display: block;
   }
}

.c-toc__content-container{
   display: block;
   @include media-breakpoint-down(md) {
      display: none;
   }
}


.c-toc__fixed-container-outer{
   &.is-fixed {
      .c-toc__fixed-container-inner{
         position: fixed;
         top: $header-height;
      }
   }
   @include media-breakpoint-down(md) {
      order: -1;
   }
}

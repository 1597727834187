

.m-account__nav{
  box-shadow: inset 0 -1px 0 0 #E6E6E6;
  //height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.m-account__nav-link{
  font-size: 0.875rem;
  letter-spacing: -0.08px;
  color: var(--color-grey-5);
  padding: 0.9rem 1rem;

  &.is_active{
    color: var(--color-dark);
    box-shadow: inset 0 -2px 0 0 var(--color-primary);
  }

  &:hover{
    text-decoration: none;
  }
}


.m-registration,.m-profile, .m-account,[class*='m-']{
  .gform_wrapper{
    padding: 2rem;
    background-color: var(--color-tertiary-12);
    border-radius: $border-radius;
  }
}

//@see ~bootstrap/scss/_variables.css


//Typography

// Base font
//$font-family-base: 'Arial', sans-serif;

// Heading font
//$font-family-header: 'Arial', serif;

// FontAwesome
$font-awesome: 'Font Awesome 5 Pro';
$font-awesome-light: 300;
$font-awesome-regular: 500;
$font-awesome-solid: 900;

// Font sizes
$font-size-body: var(--font-size);

// Font weights
$font-weights-regular: 400;
$font-weights-medium: 500;
$font-weights-semi-bold: 600;
$font-weights-bold: 700;

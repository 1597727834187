.b-hero-slider {
  @include media-breakpoint-down(md) {
    .container {
      padding: 0;
    }
  }

  .tns-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;

    button {
      background-color: var(--color-tertiary-48);
      border: none;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      margin: 4px;
      padding: 0;

      &.tns-nav-active {
        background-color: var(--color-primary);
        width: 24px;
        height: 24px;
      }
    }

    @include media-breakpoint-down(md) {
      position: relative;
      margin-top: -2rem;
      z-index: 1;
    }
  }

  button[data-action="stop"],
  button[data-action="start"] {
    border: none;
    background-color: transparent;
    color: var(--color-primary);
    position: absolute;
    margin-top: 0.5rem;
    font-size: 1.2rem;

    @include media-breakpoint-down(md) {
      margin-top: -2rem;
      left: 1rem;
      z-index: 2;
    }
  }

  .tns-outer {
    position: relative;

    @include media-breakpoint-down(md) {
      margin-bottom: -2rem;
    }
  }

  .tns-controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: calc(50% - 40px);
    transform: translateY(-50%);
    z-index: 1;

    button {
      border: none;
      height: 48px;
      width: 48px;
      border-radius: 100%;
      background-color: var(--color-light);
      color: var(--color-primary);
    }

    button[data-controls="prev"] {
      margin-left: 1rem;
    }

    button[data-controls="next"] {
      margin-right: 1rem;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.b-hero-slider__image {
  width: 100%;
  max-width: 1440px;
  height: 400px;
  object-fit: cover;

  @include media-breakpoint-down(md) {
    height: 250px;
  }
}

.b-hero-slider__slide-container {
  position: relative;
  overflow: hidden;

  .c-button {
    visibility: hidden;
  }

  &.tns-slide-active {
    .c-button {
      visibility: visible;
    }
  }
}

.b-hero-slider__slide--mobile {
  @media (min-width: 455px) {
    display: none;
  }
}

.b-hero-slider__slide--desktop {
  display: none;

  @media (min-width: 455px) {
    display: block;
  }
}

.b-hero-slider__shape-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotateX(180deg);

  &.right {
    right: -3rem;
  }

  &.left {
    left: -3rem;
  }

  @include media-breakpoint-down(md) {
    top: 25%;

    svg {
      width: 60%;
    }

    &.right {
      right: 0;

      svg {
        float: right;
        margin-right: -3rem;
      }
    }

    &.left {
      left: 0;

      svg {
        float: left;
        margin-left: -3rem;
      }
    }
  }
}

.b-hero-slider__text-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  background-color: var(--color-light);
  box-shadow: 0 2px 24px 0 rgb(28 28 27 / 36%);
  padding: 1.3rem;
  width: 400px;

  &.right {
    right: 120px;
  }

  &.left {
    left: 120px;
  }

  @include media-breakpoint-down(md) {
    position: relative;
    transform: none;
    width: calc(100% - 2rem);
    margin-top: -3rem;
    margin-bottom: 3rem;

    &.right {
      left: 1rem;
      right: 1rem;
    }

    &.left {
      left: 1rem;
      right: 1rem;
    }
  }
}

.b-hero-slider__title {
  font-size: 1.1rem;
  font-weight: 600;
}

.b-hero-slider__text {
  font-size: 0.88rem;
}

.b-podcasts {
  
  a {
    text-decoration: none;
  }
}

.b-podcasts__featured-podcast-thumbnail {
  height: 588px;

  img{
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.b-podcasts__title {
  margin-bottom: 24px;
}

.b-podcasts__featured-podcast-title {
  font-size: 1.375rem;
  line-height: 1.27;
  color: var(--color-dark);
  margin-bottom: 0.5rem;
  margin-top: 1rem;

  @include media-breakpoint-down(xs){
    max-width: 500px;
    font-size: 1.1rem;
  }
}

.b-podcasts__featured-podcast-text {
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: var(--color-dark);

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

  @include media-breakpoint-down(xs){
    margin-bottom: 40px;
    -webkit-line-clamp: 4; /* number of lines to show */
  }
}

.b-podcasts__other-podcasts-container {
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-column-gap: 10px;
  max-height: 120px;

  overflow: hidden;
  text-overflow: ellipsis;

}


.b-podcasts__other-podcast {
  height: 120px;
  width: 120px;
  background-color: black;
  display: inline-block;

  img{
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.b-podcasts__other-podcast-title {
  font-size: 1rem;
  line-height: 1.18;
  color: var(--color-dark);
  margin-top: 0.1rem;
  max-width: 100%;
}

.b-podcasts__other-podcast-text {
  font-size: 0.75rem;
  line-height: 15px;
  max-width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.b-podcasts--button-container {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;

  @include media-breakpoint-down(xs){
    position: relative;
  }
}

.b-podcasts__blur {
  overflow:hidden;
  position: relative;

  &:after {
    content: '';
    display: block;
    height: 72px;
    width: 72px;
    background-color: var(--color-primary);
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 100%;
    background-image: url("../img/headphones-solid.svg");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
  }

  img{
    transform: scale(1.1);
    filter: blur(3px);
  }
}

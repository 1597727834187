

.t-vacancy-single__top-right{
  height: 162px;
  width: 162px;
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem;
}

.t-vacancy-single__top-left{
  max-width: 665px;
}

.t-vacancy-single__info-card{
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);

}
.t-vacancy-single__info-card-details{
  font-size: 0.875rem;
}

.t-vacancy-single__info-card-icon{
  color: var(--color-primary);
  display: inline-block;
  min-width: 25px;
}


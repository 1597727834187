.s-wrapper {
  overflow-x: hidden;
  min-height: 100vh;
  padding-top: calc(#{$headerheight} + #{$subheaderheight});

  //@include media-breakpoint-down(md) {
  //  padding-top: $headerheight;
  //}

  @if $context != 'editor' {
    @extend .d-flex, .flex-column, .justify-content-start;
    overflow-x: hidden;
  }

}

.admin-bar{
  .s-wrapper{
    padding-top: calc(#{$headerheight} + #{$subheaderheight} + #{$adminbarheight} );
  }
}



.b-hero-navi {
position: relative;
}

.b-hero-navi__wrapper {
  padding-top: 4.625rem;
  @include media-breakpoint-up(md) {
    padding: 5.5rem 6.8125rem 0;
  }
}

.b-hero-navi__title {
  z-index: 2;
  position: relative;
  width: 20.4375rem;
  color: var(--color-light);
  text-shadow:0 2px 4px rgba(0, 0, 0, 0.06);
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.18;
  margin-bottom: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  max-height: 104px;
  @include media-breakpoint-up(sm) {
    -webkit-line-clamp: 3;
    line-clamp: 3;
    max-height: 112px;
    width: 43.875rem;
    font-size: 2rem;
    line-height: 1.167;
    text-shadow:
            0 4px 6px rgba(0, 0, 0, 0.06);
  }
  @include media-breakpoint-up(md) {
    max-height: 168px;
    width: 45.9375rem;
    font-size: 3rem;
    margin-bottom: 80px;
  }
}

.b-hero-navi__image {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 15.125rem;

  @include media-breakpoint-up(md) {
    height: 25rem
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:after{
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.20);
  }
}

.b-hero-navi__image--hide-fade {
  &::after {
    display: none;
  }
}

.b-hero-navi__content {
  padding: 1.5rem 1.75rem;
  background: var(--color-light);

  border-radius: 4px;
  @include media-breakpoint-up(md) {
    margin-right:2.5rem
  }
  @include media-breakpoint-up(lg) {
    margin-right:5rem
  }
}

.b-hero-navi__subtitle {
  color: var(--color-dark);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 2rem;
}

.b-hero-navi__content-wrapper {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.b-hero-navi__links {
  background: var(--color-primary-darker);
  padding: 1.5rem 1.75rem;
  width: 100%;
  border-radius: 0.25rem;

  @include media-breakpoint-up(md) {
    min-width: 320px;
    width:320px;
  }
}

.b-hero-navi__navigation-title {
  color: var(--color-light);
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.43;
  white-space: nowrap;
}

.b-hero-navi__navigation-list {
  padding-inline-start: 0;
  margin-block: 0;
  list-style: none;
}

.b-hero-navi__navigation-item {
  color: var(--color-light);
  margin-bottom: 0.75rem;

  i {
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    i {
      transform: translateX(0.25rem);
    }

    a {
      color: var(--color-grey-6);
      text-decoration: none;
    }
  }
}

.b-hero-navi__navigation-link {
  color: var(--color-light);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin-left: 1rem;
}

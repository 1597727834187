.c-podcast-card__thumbnail {
  position: absolute;
  top: 0;
  width: 100%;
  height: 195px;

  img {
    object-fit: cover;
    width: 100%;
    height: 195px;
  }
  &:after {
    margin-top: 90px;
    content: '\f130';
    font-family: $font-awesome;
    font-weight: 900;
    font-size: 18px;
    text-align: center;
    line-height: 2.66;
    color:var(--color-light);
    display: block;
    height: 48px;
    width: 48px;
    background-color: var(--color-primary);
    position: absolute;
    left:calc(50% - 24px);
    border-radius: 100%;
  }

  &.has-duration {
    &:after {
      top: -40px;
    }
  }
}

.c-podcast-card {
  padding-top: 187px;
  position: relative;
  background-color: $white;
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);

  &:hover,
  &:focus {
    text-decoration: none;

    .c-podcast-card__title {
      text-decoration: underline;
    }
  }
}

.c-podcast-card__logo {
  display: block;
}

.c-podcast-card__duration {
  position: absolute;
  padding: 4px 8px;
  margin: auto;
  color: white;
  background-color: rgba(28,28,27,0.7);
  border-radius: 4px;
  left: 0;
  right: 0;
  text-align: center;
  max-width: 60px;
  top: 110px;
  font-size: 13px;
}

.c-podcast-card__date {
  margin-bottom: 6px;
  color: var(--color-primary);
  text-transform: uppercase;
  font-size: 0.625rem;
  letter-spacing: 0.03125rem;
  font-weight: $font-weight-bold;
  &:hover,
  &:focus {
    text-decoration: none !important;
  }
}

.c-podcast-card__title {
  margin-top: 6px;
}

.c-podcast-card__content{
  min-height: 2rem;
  padding: 1.11rem 1.11rem 15px;
}
.c-information-card {
  position: relative;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: var(--shadow-lighter);
  transition: $transition-default;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none !important;
  background: var(--color-card-background);

  &:hover,
  :active {
    color: var(--color-dark);
    text-decoration: none;

    .c-information-card__title {
      color: var(--color-secondary);
      text-decoration: underline;
    }
  }

  &:hover {
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 16%);
  }

  &:active {
    background: var(--color-grey-2);
  }

  .c-item-card__image {
    img {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}

.c-information-card__top {
  padding: 1.111rem;
  min-height: 110px;
  flex-grow: 1;
}

.c-information-card__title {
  font-size: 1rem;
  line-height: 22px;
  margin-bottom: 0;
}

.c-information-card__content {
  border-top: 1px solid $border-color;
  font-size: 0.722rem;
  padding: 1.111rem;
  flex-grow: 4;
}

.c-information-card__category {
  color: var(--color-secondary);
  text-transform: uppercase;
  font-size: 0.611rem;
  font-weight: bold;
}



.b-hero-three__container {
  display: grid;
  grid-template-columns: 66% 1fr;
  grid-column-gap: 1.5rem;

  @include media-breakpoint-down(xs){
    grid-template-columns: 1fr;
    grid-row-gap: 1.5rem;
  }
}

.b-hero-three__featured-block {
  width: 100%;
  height: 30rem;
  text-decoration: none;
  position: relative;

  @include media-breakpoint-down(xs){
    height: 20rem
  }
}

.b-hero-three__image {

  position: absolute;
  height: 100%;
  width: 100%;


  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.b-hero-three__featured-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 2.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
  word-wrap: break-word;
  z-index: 1;

  @include media-breakpoint-down(xs){
    padding-bottom: 2rem;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  }
}

.b-hero-three__featured-post-type{
  font-size: 1rem;
  line-height: 1.25rem;
  color: #F9ECDC;
  margin-bottom: 0.25rem;

  @include media-breakpoint-down(xs){
    font-size: 0.8rem;
  }
}

.b-hero-three__featured-title {
  font-size: 2.25rem;
  line-height: 1.17;
  color: var(--color-light);
  text-shadow: 0 2px 4px rgba(3,0,92,0.12);
  margin-bottom: 0.25rem;
  font-weight: $font-weights-medium;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @include media-breakpoint-down(xs){
    font-size: 1.5rem;
  }
}

.b-hero-three__featured-date {
  color: #B8B8B2;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 0;

  @include media-breakpoint-down(xs){
    font-size: 0.8rem;
  }
}

.b-hero-three__other-blocks {
  display: grid;
  grid-template-rows: repeat(2, 14.25rem);
  grid-row-gap: 1.5rem;
  position: relative;
}

.b-hero-three__other-block {
  width: 100%;
  height: 100%;
  position: relative;
}

.b-hero-three__other-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  word-wrap: break-word;
  z-index: 1;
}

.b-hero-three__other-post-type{
  font-size: 1rem;
  color: #F9ECDC;
  letter-spacing: 0;
  line-height: 1.25rem;
  margin-bottom: 5px;

  @include media-breakpoint-down(sm){
    font-size: 0.8rem;
  }
}

.b-hero-three__other-title {
  font-size: 1.375rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.2;
  color: var(--color-light);
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @include media-breakpoint-down(sm){
    font-size: 1.2rem;
  }

  @include media-breakpoint-down(xs){
    max-width: 85%;
  }
}

.b-hero-three__other-date {
  color: #B8B8B2;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  margin-bottom: 0;

  @include media-breakpoint-down(sm){
    font-size: 0.8rem;
  }
}


.b-hero-three__gradient {
  &:after {
    content: '';
    display: block;
    background: linear-gradient(180deg, rgba(28,28,27,0) 0%, rgba(28,28,27,0.70) 100%);
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: 0;

    @include media-breakpoint-down(xs){
      height: 100%;
    }
  }
}

.b-hero-three__label {
  position: absolute;
  bottom: 8px;
  left: 8px;
  padding: 2px 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: var(--color-light);
  color: var(--color-primary);
  font-weight: $font-weights-medium;
  font-size: 10px;
  line-height: 0.7619rem;
  font-family: var(--font-family);

  .c-information-card & {
    left: 17px;
  }
}

.b-hero-three__label--top {
  top: 8px;
  bottom: auto;
}

.b-hero-three__label--right {
  right: 8px;
  left: auto;
}

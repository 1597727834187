
.c-select {
  //
  //appearance: none;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  background: transparent;
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  padding: 0.6rem 3rem .6rem 1rem;
  //display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  position: relative;
  color: inherit;

  &:hover {
    color: var(--color-primary);
    box-shadow: var(--shadow-default);
  }

  &:focus, &--active {
    color: var(--color-primary);
    box-shadow: var(--shadow-inset);
    background-color: var(--color-grey-1);
    outline: none;
  }


}

.c-select--label {
  color: var(--color-primary);
  position: relative;
}

.c-select--label-arrow {
  width: 48px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  svg path {
    fill: currentColor;
  }
}

.c-select--label-icon{
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-select--small {
  height: auto;
  padding: 0.5rem 3rem .5rem 1rem;
}

.c-select--icon{
  padding: 0.6rem 3rem .6rem 2.5rem;
}

.b-picture-slider {

  @include in_editor {
    .b-picture-slider__slider {
      align-items: stretch;
    }
  }

  .tns-ovh {
    overflow: visible;
  }

  .tns-outer {
    position: relative;
  }

  .tns-controls {
  }

  .tns-controls button {
    z-index: 1;
    border: none;
    border-radius: 4px;
    padding: 12px 16px;
    background-color: transparent;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
      background-color: $white;
    }
  }

  button[data-controls="next"] {
    right: -55px;

    @include media-breakpoint-up(lg) {
      right: -150px;
    }

    @include media-breakpoint-up(xl) {
      right: -250px;
    }
  }

  button[data-controls="prev"] {
    left: -55px;

    @include media-breakpoint-up(lg) {
      left: -150px;
    }

    @include media-breakpoint-up(xl) {
      left: -250px;
    }
  }

  .tns-item {
    opacity: 1;
    transition: 0.2s ease-in-out;
    transition-property: transform, opacity;
    transform: scale(0.8);

    @include media-breakpoint-down(sm) {
      transform: none;
    }

    &.is-hidden {
      opacity: 0;
    }
  }

  .tns-slide-active {
    transform: scale(1);

    .b-picture-slider__image-container img {
      opacity: 1;
    }

    .b-picture-slider__content {
      height: auto;
      opacity: 1;
      transition: opacity 0.2s ease-in-out .3s;
    }
  }
}

.b-picture-slider__content {
  opacity: 0;
  position: absolute;
}

$background-color: #FEFAF7;

.b-picture-slider__slider {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 290px;

  @include media-breakpoint-down(sm) {
    align-items: end;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    background: linear-gradient(0deg, rgba($background-color, 0) 0%, rgba($background-color, 1) 10%, rgba($background-color, 1) 90%, rgba($background-color, 0) 100%);
    top: 0;
    height: 100%;
    width: 200%;
    z-index: -1;
    transform: translateX(-20%);
  }
}

.b-picture-slider__image-container {
  background-color: rgb(28, 28, 27);

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: .3;
    transition: opacity 0.2s ease-in-out;
  }
}


.b-picture-slider__title {
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 18px;
  letter-spacing: -0.2px;
  line-height: 20px;
}

.b-picture-slider__description {
  margin-top: 0.75rem;
  margin-bottom: 0;
  font-size: 1.0625rem;
  letter-spacing: -0.00625rem;
  line-height: 1.875rem;
}

.b-picture-slider__photographer {
  margin-top: 0.75rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
}

.b-picture-slider__in-the-photo {
  margin-top: 0.3125rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
}

.b-picture-slider__overlay-background {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 4;
  height: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  pointer-events: none;

  &.is-open {
    height: auto;
    opacity: 1;
    pointer-events: auto;
    z-index: 999;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #1C1C1B;
    opacity: 0.85;
    z-index: -1;
  }

  .tns-ovh {
    overflow: hidden;
  }

  .b-picture-slider__content {
    background-color: rgba(255, 255, 255, 0.95);
    padding: 12px 16px 16px 16px;
    transition: 0.2s ease-in-out;
    margin-top: 12px;
  }

  .b-picture-slider__image-container {
    background-color: transparent;
  }

  .b-picture-slider__slide-container {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  img {
    max-height: 80vh;
    object-fit: contain;
  }

  .b-picture-slider__slider {
    align-items: center;

    &:after {
      display: none;
    }
  }

  .tns-outer {
    height: 100vh;
  }

  .tns-controls button {
    display: none;
    background-color: $white;
    @include media-breakpoint-up(md) {
      position: absolute;
      display: block;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  button[data-controls="next"] {
    right: -24px;
  }

  button[data-controls="prev"] {
    left: -24px;
  }
}

.b-picture-slider__slider-overlay {
  height: 100vh;
}

.b-picture-slider__overlay-close {
  z-index: 1;
  border: none;
  background-color: $white;
  border-radius: 4px;
  padding: 10px 16px;
  display: inline-block;
  font-size: 19px;
  color: var(--color-secondary);
  position: absolute;
  top: 24px;
  right: 24px;
}

.c-bibliography{
  color: var(--color-primary);
  font-weight: bold;
  font-size: 0.667rem;
  vertical-align: top;
  position: relative;
  display: inline-block;
}

.c-bibliography__shortcode-text{
  display: none;
  position: absolute;
  color: var(--color-dark);
  font-weight: normal;
  background-color: #FFFFFF;
  z-index: 10;
  box-shadow: 0 2px 24px 0 rgba(0,0,0,0.16);
  border-radius: 4px;
  padding: 0.7rem 1.625rem;
  width: 435px;
  left:-217px;
  top: 1.5rem;

  .c-bibliography{
    display: none;
  }

  &.setLeft{
    left: -0.9rem;
    .c-bibliography__triangle{
      left: 1rem;
    }
  }
  &.setRight{
    left: -240px;
    .c-bibliography__triangle{
      left: 230px;
    }
  }

  @include media-breakpoint-down(md) {
    left: -125px;
    width: 250px;
  }
}
.c-bibliography__shortcode-text-number{
  position: absolute;
  left: 0.8rem;
  color: var(--color-primary);
  font-weight: bold;
  font-size: 0.667rem;
}

.c-bibliography__triangle{
  width: 50px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  overflow: hidden;
  &::after{
    content: "";
    position: absolute;
    width: 0.8rem;
    height: 0.8rem;
    background: #fff;
    transform: translateX(-50%) translateY(-50%) rotate(
                    45deg
    );
    top: 100%;
    left: 50%;
  }
}

.c-bibliography__number{
  cursor: pointer;
  display: inline-block;
  &:hover{
    .c-bibliography__shortcode-text{
      display: block
    }
  }
}

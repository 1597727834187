.b-header-slider {
  @include in_editor {

    height: 200px;

    .b-header-slider__main-slider {
      display: none;
    }

    .b-second-slider__slide-thumbnail {
      float: left;
    }
  }

  &:hover,
  &:focus {
    .b-header-slider__copyright-text {
      opacity: 1;
    }
  }
}

.b-header-slider__main-image-container {
  width: 101%;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.b-header-slider__thumbnails {

  margin-top: 12px;
  position: relative;
  width: calc(100% - 120px);
  margin-left: 60px;

  button {
    border: none;
    margin-top: 36px;
    height: 18px;
    width: 16px;
    background-color: var(--color-primary);
    -webkit-mask-image: url("../img/arrow-right-light.svg");
    mask-image: url("../img/arrow-right-light.svg");
    background-repeat: no-repeat;
    position: absolute;
  }

  button[data-controls="prev"] {
    transform: rotate(180deg);
    left: -60px;
  }

  button[data-controls="next"] {
    right: -60px;
  }

}

.b-second-slider__slide-thumbnail {
  height: 85px;
  width: 85px;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.b-header-slider__thumbnails--small {
  .tns-inner {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.b-header-slider {
  #tns1-ow {
    button {
      display: none;
    }
  }
}

.b-header-slider__copyright-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  background-color: rgba(28,28,27,0.6);
  overflow: hidden;
  font-size: 0.778rem;
  padding: 0.4rem 0.5rem;
  height: auto;
  font-weight: $font-weights-semi-bold;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.b-header-slider__copright-link {
  color: white !important;
  text-decoration: underline;
  margin-left: 0.3rem;
}

.b-header-slider__slide-container {
  position: relative;
}

.b-wp-core-image {
  position: relative;

  figcaption {
    display: block;
  }

  .wp-block-image {
    &.aligncenter {
      margin: 0 auto;

      > figcaption {
        text-align: center;
        width: 100%;
        display: block;
      }
    }

    &.alignright {
      float: right;
      margin: 0 0 1em 1em;
    }

    &.alignleft {
      float: left;
      margin: 0 1em 1em 0;

      figcaption {
        text-align: left;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  figcaption {
    text-align: left;
    color: rgb(0 0 0 / 65%);
    font-size: 14px;
    font-style: italic;
    display: block;
  }

  &.has_copyright {
    cursor: pointer;

    &:hover {
      .c-copyright__content {
        display: block;
      }
    }
  }

  .wp-block-image.aligncenter {
    display: block;
  }
}

.t-single__content {
  .b-wp-core-heading + .b-wp-core-image {
    margin-top: 2em;
  }
}

//Add your own variables here!
/*
 * Add your own variables here
 *
 * Think about breakpoints (that effect multiple components, default settings for transients, border-radius or box-shadows
 */

// Animations and transitions
$transition-default-speed: 100ms;
$transition-default-ease: ease-in-out;
$transition-default: $transition-default-speed $transition-default-ease;

// Menu variables
$menu-breakpoint: md;

// Z-index
$z-index-header: 100;

$border-radius: 4px;
$header-height: 116px;

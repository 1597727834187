
.b-wp-core-quote{

}

.wp-block-quote{
  border-radius: 4px;
  background-color: rgba(0,0,0,0.05);
  border-left: none;
  position: relative;
  padding: 2rem ;

  &:before{
    content: '\f10d';
    color: var(--color-primary);
    position: absolute;
    top: 1rem;
    left: -2rem;
    font-family: $font-awesome;
    font-weight: bold;
    font-size: 56px;
  }

  cite{
    font-style: italic;
    font-weight: 500;
    color: var(--color-dark);
    font-size: 1rem;
    &:before{
      content: '— ';
      position: relative;
      font-weight: 500;
    }
  }
  @include media-breakpoint-down(md) {
    padding: 1rem 1.5rem;
    &:before{
      font-size: 24px;
      left: -0.5rem;
      top: 0.5rem;
    }

  }
}
.b-bibliography{
  position: relative;

}

.b-bibliography__list{
  transition: height $transition-default;
  ul, ol{
    list-style-type: none;

    li{
      margin-left: -1rem;
      position: relative;
      font-size: 0.75rem;
      margin-bottom: 0.9rem;

      .c-bibliography {
        position: absolute;
        left: -1rem;
      }
    }
  }

  &.limitShow{
    max-height: 120px;
    overflow: hidden;
    transition: height $transition-default;
  }
}


.b-bibliography__button-container{
  margin-top: -150px;
  display: none;
  height: 150px;
  background: linear-gradient(0deg, #FFFFFF 0.08%, rgba(255,255,255,0) 100%);
  box-shadow: inset 0 -1px 0 0 #E6E6E6;
  position: relative;
  z-index: 2;
  text-align: center;
  &.show{
    display: block;
  }

  &.open{
    background: none;
    height: 170px;
    .b-bibliography__button{
      margin-top: 150px;
    }
  }
}

.b-bibliography__button{
 margin-top: 130px;
  text-align: center;
}


.c-loader {
  height: 0;

  > div {
    opacity: 0;
  }

  &.is_loading {
    height: auto;
    //display: block;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    > div:first-child {
      opacity: 0;
      animation-name: fadeIn;
      animation-duration: .3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    > div:nth-child(2) {
      opacity: 0;
      animation-name: fadeIn;
      animation-duration: .3s;
      animation-iteration-count: 1;
      animation-delay: .15s;
      animation-fill-mode: forwards;

      //@include respond-max($bp-md) {
      //  display: none;
      //}
    }

    > div:nth-child(3) {
      opacity: 0;
      animation-name: fadeIn;
      animation-duration: .3s;
      animation-iteration-count: 1;
      animation-delay: .3s;
      animation-fill-mode: forwards;

      //@include respond-max($bp-sm){
      //  display:none;
      //}

    }

    > div:nth-child(4) {
      opacity: 0;
      animation-name: fadeIn;
      animation-duration: 0;
      animation-iteration-count: 1;
      animation-delay: 0;
      animation-fill-mode: forwards;
    }
  }
}

.c-loader__post {
  div {
    display: block;
    background: rgba(0, 0, 0, 0.05);
  }

  div:first-child {
    min-width: 100%;
    display: block;
    height: 216px;
    background: rgba(0, 0, 0, 0.05);
    margin-bottom: 1rem;

  }

  div:nth-child(2) {
    height: 25px;
    width: 82%;
    margin-bottom: 1rem;
  }

  div:nth-child(3), div:nth-child(4) {
    width: 60%;
    height: 20px;
    margin-bottom: 1rem;
  }

  div:nth-child(4) {
    width: 40%;
  }
}

.c-loader__post-line {
  div:first-child {
    height: 25px;
  }
}

.c-loader__spinner {
  display: block;
  text-align: center;
  color: var(--color-primary);
}

.c-loader__spinner--hide {
  display: none;
}

.c-related-loader__spinner {
  display: none;
  text-align: center;
  margin-bottom: 24px;

  &.is-loading {
    display: block;
  }
}


.b-hero-slider-two{
  overflow: hidden;
  position: relative;
  max-height: 400px;

  @include media-breakpoint-down(xs) {
    max-height: 320px;
  }

  @include media-breakpoint-down(md) {

    .container{
      padding: 0;
    }
  }


  .tns-nav{
    position: absolute;
    bottom: 0.75rem;
    left: 0;
    right: 0;
    text-align: center;

    button{
      background-color: var(--color-light);
      border: none;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      margin: 4px;
      padding: 0;
      outline: none;

      &.tns-nav-active{
        background-color: var(--color-secondary);
        width: 24px;
        height: 24px;

      }
    }
    @include media-breakpoint-down(md) {
      margin-top: -2rem;
      z-index: 1;
    }
  }
  button[data-action="stop"], button[data-action="start"]{
    border: none;
    background-color: transparent;
    color: var(--color-primary);
    position: absolute;
    bottom: 0.75rem;
    left: 15px;
    font-size: 1.2rem;
    z-index: 1;
    outline: none;

    @include media-breakpoint-down(md) {
      margin-top: -2rem;
      left: 1rem;
      z-index: 2;
    }
  }

  .tns-outer{
    display: flex;
    justify-content: center;
    position: relative;
  }
  .tns-controls{
    width: 95%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    outline: none;

    button{
      border: none;
      height: 48px;
      width: 48px;
      border-radius: 100%;
      background-color: var(--color-light);
      color: var(--color-secondary-darker);
    }
    button[data-controls="prev"]{
      margin-left: 0.75rem;

    }

    button[data-controls="next"]{
      margin-right: 0.75rem;

    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

}

.b-hero-slider-two__image{
  width: 100%;
  max-width: 1440px;
  height: 25rem;
  object-fit: cover;

  @include media-breakpoint-down(xs){
    height: 20rem;
  }
}

.b-hero-slider-two__slide-container{
  position: relative;
  overflow: hidden;

  .c-button{
    visibility: hidden;
  }

  &.tns-slide-active{
    .c-button{
      visibility: visible;
    }
  }
}

.b-hero-slider-two__title{
  font-size: 2.5rem;
  font-weight: $font-weights-bold;
  letter-spacing: 0;
  line-height: 1.16;
  color: white;
  max-width: 40rem;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 2px rgba(0,0,0,0.40);

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  @include media-breakpoint-down(xs){
    max-width: 100%;
    font-size: 1.4rem;
    -webkit-line-clamp: 6;
  }
}

.b-hero-slider-two__text{
  font-size: 0.88rem;
}

.b-hero-slider-two-content-container {
  position: absolute;
  top: 7rem;

  @include media-breakpoint-down(xs){
    top: 3rem;

    width: 80%;
  }
}


.b-hero-slider-two-content-container--left {
  left: 8.125rem;

  @include media-breakpoint-down(sm){
    left: 3rem;
  }
}

.b-hero-slider-two-content-container--right {
  right: 8.125rem;
  text-align: right;

  @include media-breakpoint-down(sm){
    right: 3rem;
    width: 80%;

    text-align: right;
  }
}

.b-hero-slider-two-content-container--center {
  right: 0;
  left: 0;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  .b-hero-slider-two__title {
    margin-left: auto;
    margin-right: auto;


    -webkit-line-clamp: 3;

    @include media-breakpoint-down(xs) {
      -webkit-line-clamp: 6;
    }
  }
}

.b-hero-slider-two__container{
  position: relative;
}

.b-hero-slider-two__nav{
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 2.5rem;
  right: 2.5rem;
  pointer-events: none;


  span{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background-color: var(--color-light);
    border-radius: 100%;
    z-index: 2;
    width: 48px;
    pointer-events: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  @include media-breakpoint-down(md){
    display: none;
  }
}


.s-footer {
  padding: 3rem 0 1rem;

  a {
    color: var(--color-primary);
  }
}

.s-footer__content {
  font-size: 0.77rem;
  line-height: 1.22rem;
}

.s-footer__logo {
  max-width: 214px;
}

.s-footer__disclaimer {
  font-size: 0.77rem;
  line-height: 1.22rem;

  > span {
    display: flex;
    align-items: center;
  }
}

.s-footer__partners {
  margin-bottom: 1rem;
}

.s-footer__partners-title {
  font-size: 0.9rem;
  color: var(--color-grey-5);
}

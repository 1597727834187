$icon-arrow-width: 20px;
$link-padding: 20px;
$link-padding-right: calc(#{$link-padding} + #{$icon-arrow-width});

.b-quick-links {
}

.b-quick-links--background {
   background-color: var(--color-tertiary-24);
   padding: 48px 0;

   @include media-breakpoint-down(md) {
      padding: 32px 0;
   }
}

.b-quick-links__grid {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-gap: 24px;

   @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
   }

   @include media-breakpoint-down(xs) {
      grid-template-columns: 1fr;
      grid-gap: 16px;
   }
}

.b-quick-links__title {
   font-size: 2rem;
   line-height: 2.125rem;
   font-weight: $font-weights-bold;
   margin-bottom: 1rem;

   @include media-breakpoint-down(md) {
      font-size: 1.625rem;
      line-height: 1.75rem;
   }
}

.b-quick-links__title--left {

}

.b-quick-links__title--center {
   text-align: center;
}

.b-quick-links__link {
   position: relative;
   padding: $link-padding $link-padding-right $link-padding $link-padding;
   transition: background-color $transition-default;
   background: var(--color-light);
   border-radius: $border-radius;
   box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
   height: 96px;
   z-index: 2;

   &:before {
      content: '';
      background-color: var(--color-secondary);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: $border-radius;
      box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.13);
      transition: transform $transition-default, opacity $transition-default;
      opacity: 0;
      z-index: 1;
   }

   &:hover {
      text-decoration: none;
      background-color: var(--color-secondary);

      &:before {
         transform: scaleY(1.16);
         opacity: 1;
      }

      .b-quick-links__link-title {
         color: var(--color-light);
      }

      .b-quick-links__icon {
         &:before,
         &:after {
            transform: translateX(4px);
         }

         &:before {
            opacity: 1;
         }

         &:after {
            opacity: 0;
         }
      }
   }
}

.b-quick-links__link-title {
   font-size: 1.125rem;
   line-height: 1.375rem;
   font-weight: $font-weights-bold;
   transition: color $transition-default;
   color: var(--color-dark);
   overflow: hidden;
   position: relative;
   z-index: 2;

   @include media-breakpoint-down(md) {
      font-size: 1rem;
   }
}

.b-quick-links__icon {
   &:before,
   &:after {
      content: '';
      display: block;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      width: $icon-arrow-width;
      height: 20px;
      position: absolute;
      right: $link-padding;
      top: $link-padding;
      transition: transform $transition-default, opacity $transition-default;
      z-index: 2;
   }

   &:before {
      background-image: url('../img/arrow-right-white.svg');
      opacity: 0;
   }

   &:after {
      background-image: url('../img/arrow-right.svg');
      opacity: 1;
   }
}

.b-faq {
  .c-accordion:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  .c-accordion__inner {
    background-color: var(--color-primary);
    border-radius: 4px;
  }

  .c-accordion__button {
    color: $white;
    padding: 1rem;
    border-radius: 4px;

    @include in_editor() {
      background-color: $gray-700;
    }
  }

  .c-accordion__title {
    position: relative;
    font-size: 1rem;
    font-weight: 600;
    color: $white;

    @include media-breakpoint-down(md) {
      padding-right: 1.5rem;
    }

    &:hover {
      cursor: pointer;
    }

    &::after {
      content: "\f078";
      font-family: $font-awesome;
      font-size: 1rem;
      font-weight: 300;
      position: absolute;
      right: 0;
      top: 50%;
      transform-origin: center;
      transform: translateY(-50%);
      transition: transform $transition-default;

      @include media-breakpoint-down(md) {
        font-size: 1rem;
      }
    }
  }

  .c-accordion__content {
    font-size: 1rem;
    display: none;
    margin-top: 0.5rem;
    color: $white;

    a {
      color: var(--color-light);
      text-decoration: underline;
    }
  }

  .c-accordion.is-open {
    .c-accordion__title::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .c-accordion--has-table {
    .c-accordion__content {
      background-color: transparent;
      color: var(--color-dark);

      table {
        width: calc(100% + 2rem);
        margin: 0 -1rem;
      }
    }
  }

  .c-accordion--has-table {
    .c-accordion__button {
      background-color: var(--color-primary);
    }

    .c-accordion__inner {
      background-color: transparent;
    }
  }

  .c-accordion__table-row {
    p {
      font-weight: $font-weights-semi-bold;
      margin: 0;
    }

    td {
      padding: 0.5rem;
      width: 50%;

      &:first-child {
        padding-left: 1rem;
      }

      &:last-child {
        text-align: center;
        padding-right: 1rem;
      }
    }

    &:not(:last-child) td {
      border-bottom: 1px solid var(--color-primary);
    }
  }
}

.c-accordion__checkmark {
  &::before {
    color: green;
  }
}

.c-accordion__times {
  font-size: 1.2em;

  &::before {
    color: red;
  }
}

.c-restricted__content-teaser{
  position: relative;
}

.c-restricted__content-teaser:after {
  content: " ";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
}


.c-organisation-card__content {
  min-height: 2rem;
  padding: 1.11rem 1.11rem;

  &--no-hover {
    &:hover {
      text-decoration: none;
    }

    .c-item-card__title {
      &:hover {
        text-decoration: none;
      }
    }
  }
}


.c-organisation-card__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  height: 144px;

  img {
    max-width: 100%;
    max-height: 100px;
    height: auto;
    display: block;
  }
}

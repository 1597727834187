
.b-two-columns{
  iframe{
    max-width: 100%;
  }

  .container--small{
    border-radius: $border-radius;
    overflow: hidden;

    @include media-breakpoint-down(sm){
      border-radius: 0;
    }
  }
}

.b-two-columns__text--white {
  color: white;

  @include in_editor() {
    color: $color-visual-editor-block-group;
  }
}


.b-two-columns__column{
  .b-two-columns__text--black {
    color: black;

    .gform_body {
      .gfield_consent_label {
        color: black;
      }
      .gfield_label {
        color: black;
      }

    }
    .gform_footer{
      input[type=submit],button[type=submit] {
        @extend .c-button, .c-button--ghost--secondary;
      }
    }
  }
  .gform_ajax_spinner {
    width: 2rem;
    height: 2rem;
    margin-left: 3rem;
  }
}

.b-two-columns__column:not(.b-two-columns--background-color-none){
  padding: 3.4375rem 6.5rem;

  @include media-breakpoint-down(lg){
    padding: 3rem 6rem;
  }

  @include media-breakpoint-down(sm){
    padding: 2rem 1rem;
  }
}

.b-two-columns__column:not(.block_type_links){
  justify-content: center;
}
.b-two-columns__column {


  .gform_body {

    .hidden_label{
      .gfield_label {
        display: none;
      }
    }

    .ginput_container{
      input{
        height: 60px;
      }
    }

    .gfield_consent_label {
      color: $white;
      margin-left: 0;
      text-align: left;
      margin-top: 1rem;

    }

    .gfield {
      padding: 0;
    }

    .gfield_label {
      font-size: 0.75rem;
      letter-spacing: 0.0625rem;
      line-height: 0.9375rem;
      color: white;
      margin-bottom: 8px;
      font-weight: $font-weights-semi-bold;
    }

    .gfield_required {
      display: none;

    }

    input[type="checkbox"] {
      appearance: none;

      &:after {
        content: '';
        display: block;
        border: 1px solid #848480;
        height: 18px;
        width: 18px;
        border-radius: 3px;
      }
    }
  }


  .gform_footer {
    margin-top: 1.3rem;
  }
}

.b-two-columns__image{
  width: 100%;
  //height: auto;
}

.b-two-columns__shape-container{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  svg{
    height: 350px;
    margin-right: -10rem;
    margin-bottom: -10rem;
  }

  &--right{
    left: 0;
    right: auto;
    transform: translateY(-50%) rotateX(180deg);
    svg{
      margin-right: 0rem;
      margin-left: -10rem;
      margin-bottom: 10rem;
    }
  }
  @include media-breakpoint-down(md) {
    svg{
      height: 250px;
    }
  }
}

.b-two-columns__image-container{
  position: relative;
  overflow: hidden;
  max-height: 304px;
  &:hover{
    .c-copyright__content{
      display: block;
    }
  }

  img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 4px;
  }
}

.block_type_image {
  padding: 3rem 0;
}

.b-two-columns--background-color-none{
  background-color: transparent;

  &.block_type_image{
    padding: 0;
  }

  &.block_type_video{
    padding: 0;
  }


  .b-two-columns__image-container{
    padding: 0;
  }

  .b-two-columns__image-container {
    max-height: 400px;
  }
}

.b-two-columns--background-color-none img {
  width: 100%;
  //height: 100%;
  object-fit: cover;
}



.b-two-columns--background-color-white {
  background-color: white;
}

.b-two-columns--background-color-primary{
  background-color:  var(--color-primary);
}

.b-two-columns--background-color-secundary {
  background-color:  var(--color-secondary);
}
.b-two-columns--background-color-tertiary-48 {
  background-color: var(--color-tertiary-24);
}

.b-two-columns--background-color-grey {
  background-color: #F2F2F2;
}


.b-two-columns__column{
  @include media-breakpoint-down(md) {
    margin-top: 1rem;
  }

}

.b-two-columns__column.block_type_image.b-two-columns--background-color-secundary{
  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
}
.b-two-columns__column.block_type_image.b-two-columns--background-color-primary{
  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
}



.b-two-columns__title-space{
  min-height: 3.5rem;

  @include media-breakpoint-down(sm) {
    min-height: auto;
  }
}

.inline-image{
  height: unset!important;
  width: unset!important;
}

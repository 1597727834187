.b-cta {

}

.b-cta--no-image {
  background: var(--color-tertiary-24);

  .b-cta__text-container,
  .b-cta__position--right .b-cta__text-container,
  .b-cta__position--center .b-cta__text-container {
    background: transparent;
  }

  .b-cta__position--center {
    background: unset;
  }
}

.b-cta__image-container{
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.b-cta__image{
  object-fit: cover;
  @include media-breakpoint-down(xs) {
    height: 150px;
  }
}

.b-cta__content-container{
  position: relative;
  height: 400px;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    height: auto;
  }
}

.b-cta__text-container{
  width: 55%;
  padding: 80px;
  background: linear-gradient(90deg, rgba(255,255,255,0.82) 0%, rgba(255,255,255,0) 100%);

  @include media-breakpoint-down(md) {
    padding: 40px;
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
    padding: 1rem 0;
  }
}


.b-cta__position--right{
  .b-cta__text-container{
    float: right;
    text-align: right;
    background: linear-gradient(270deg, rgba(255,255,255,0.82) 0%, rgba(255,255,255,0) 100%);
  }
  .b-cta__shape-container{
    left: 0;
    right: auto;
    transform: translateY(-50%) rotateX(180deg);
    svg{
      margin-right: 0rem;
      margin-left: -10rem;
      margin-bottom: 10rem;
    }
  }
}

.b-cta__position--center {
  background: linear-gradient(180deg, rgba(255,255,255,0.82) 64%, rgba(255,255,255,0) 100%);
  .b-cta__text-container {
    text-align: center;
    margin: auto;
    background: unset;
  }

  .b-cta__shape-container {
    left: 0;
    right: auto;
    transform: translateY(-50%) rotateX(180deg);
  }
}

.b-cta__shape-container{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  svg{
    height: 350px;
    margin-right: -10rem;
    margin-bottom: -10rem;
  }

}


.b-hero-slider-two + .b-cta {
  margin-top: 0;

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
}

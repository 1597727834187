// Debug stylesheet

// Show breakpoint in debug mode
$debug-enable-breakpoint:    true;

// Show current page template in debug mode
$debug-enable-page-template: true;

// Highlight images without alt or with an empty alt tag
$debug-image-alt-check:      false;

body.debug {

  &:after,
  &:before {
    position: fixed;
    right: 1em;
    bottom: 1em;
    padding: .25em .5em;
    font-size: .75rem;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, .5);
    z-index: 9999;
  }

  @if($debug-enable-breakpoint) {
    &:after {
      @each $breakpointName, $breakPoint in $grid-breakpoints {
        @include media-breakpoint-only(#{$breakpointName}) {
          content: "#{$breakpointName}";
        }
      }
    }
  }

  @if($debug-enable-page-template) {
    &:before {
      @include media-breakpoint-up(md) {
        right: auto;
        left: 1rem;
        content: attr(data-template);
        text-transform: lowercase;
      }
    }
  }

  @if($debug-image-alt-check) {
    img:not([alt]),
    img[alt=""],
    img[alt="Afbeelding voor "] {
      filter: blur(3px);
      outline: 3px solid red;
    }
  }
}
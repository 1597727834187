.c-hero-card {
  position: relative;
  background-color: var(--color-light);
  border-radius: 4px;
  padding: 12px;
  min-height: 88px;
  overflow: hidden;
  box-shadow: var(--shadow-lighter);

  &:hover {
    box-shadow: var(--shadow-darker);
  }
}

.c-hero-card__image {
  border-radius: 4px;
  min-width: 48px;
  margin-right: 20px;
  overflow: hidden;
  img {
    width: 48px;
    height: 48px;
    object-fit: cover;
  }
}

.c-hero-card__title {
  font-size: 16px;
  color: var(--color-primary);
  font-weight: 600;
  line-height: 20px;
}

.c-hero-card__text {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-dark);

}

.c-hero-card__link:hover {
  text-decoration: none;

  .c-hero-card__title {
    text-decoration: underline;
  }
}

.c-researcher-card {
  justify-content: flex-start !important;
  flex-direction: column !important;

  a:not(.c-researcher-card__button):hover,
  a:not(.c-researcher-card__button):focus {
    text-decoration: none;
    color: var(--color-dark);

    .c-item-card__title {
      color: var(--color-primary);
    }

    .c-item-card__subtitle {
      text-decoration: none;
    }
  }

  .c-item-card__subtitle,
  .c-item-card__organization {
    display: flex;
  }
}

.c-person-card__content {
  min-height: 130px;
  padding: 1.11rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.c-researcher-card__header {
  display: flex;
  gap: 8px;
}

.c-person-card__icon {
  color: var(--color-primary);
  display: block;
  min-width: 28px;
}

.c-person-card-detail {
  display: flex;
  word-break: break-all;
}

.c-researcher-card {
  .c-item-card__title {
    font-size: 1.125rem;
  }
}

.c-researcher-card__header-left {
  flex-grow: 1;
}

.c-researcher-card__header-right {
  min-width: 91px;
  max-width: 91px;
}

.c-researcher-card__info {
  background-color: hsl(191deg 52 96 / 100%);
  padding: 12px;
  margin: 1rem 0;
  width: 100%;
}

.c-researcher-card__info-table {
  th {
    padding-right: 0.5rem;
    vertical-align: top;
  }

  tr:not(:last-of-type) > * {
    padding-bottom: 0.75rem;
  }
}

.c-researcher-card__button {
  @extend .c-button;
  @extend .c-button--ghost;

  text-align: center;
}

.c-researcher-card__buttons {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: auto;

  a {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: calc(50% - 0.25rem);
    }
  }

  .c-button + .c-button {
    margin: 0;
  }
}

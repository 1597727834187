.b-audio__container {
  text-align: center;
  width: 100%;
  margin-top: 24px;
  position: relative;
  min-height: 250px;

  @include media-breakpoint-up(sm){
    min-height: 400px;
  }

  audio {
    width: 94%;
    margin: 0 auto;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;

  }

  img {
    min-height: 250px;
    @include media-breakpoint-up(sm){
      min-height: 400px;
    }
  }

  audio::-webkit-media-controls-panel {
    background-color: var(--color-primary-translucent);
  }


  &:after {
    content: '\f130';
    font-family: $font-awesome;
    font-weight: 900;
    font-size: 18px;
    text-align: center;
    line-height: 2.33;
    color:var(--color-light);
    display: block;
    height: 42px;
    width: 42px;
    background-color: var(--color-primary);
    position: absolute;
    left:calc(50% - 21px);
    top:25%;
    border-radius: 100%;

    @include media-breakpoint-up(sm) {
      height: 72px;
      width: 72px;
      font-size: 28px;
      line-height: 2.571;
      left:calc(50% - 36px);
      top:calc(50% - 36px);
    }
  }
}

.b-audio__duration {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  color: white;
  background-color: rgba(28, 28, 27, 0.7);
  border-radius: 4px;
  text-align: center;
  max-width: 60px;
  z-index: 1;
  font-size: 13px;

  @include media-breakpoint-up(sm) {
    top: 60%;
  }
}
.c-epd-card__content{
  min-height: 2rem;
  padding: 1.11rem 1.11rem 15px;

  &:hover,
  &:focus {
    text-decoration: none !important;
  }
}


.c-epd-card__logo{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
}

.c-epd-card__company-logo {
  max-width: 100%;

  img {
    object-fit: contain;
    max-height: 110px;
    max-width: 100%;
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(md) {
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      margin: 15px 0 15px 10px;
      width: 150px;
    }
  }

  @include media-breakpoint-down(xs) {
    img{
      margin: 15px 0 15px 10px;
    }
  }
}

.c-epd-card__company {
  color: #848480;
  font-size: 0.625rem;
  letter-spacing: 0.0625rem;
  line-height: 0.8125rem;
  text-transform: uppercase;
  margin-bottom: 8px;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #848480;
  }
}

.c-epd-card__title {
  font-size: 1rem;
  font-weight: $font-weights-semi-bold;
  letter-spacing: 0;
  line-height: 1.1875rem;
  margin-bottom: 8px;
}

.c-epd-card__location {
  display: inline;
  margin-left: 0.75rem;
}

.c-epd-card__location-icon {

}

.c-epd-card__image {
  border-top: 1px solid #e5e5e5;
}

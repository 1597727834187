
.b-cards__position--center{
  text-align: center;
}

.b-cards__position--right{
  text-align: right;
}

.b-cards__margin-bottom {
  margin-bottom: 24px;
}

.b-cards__introtext{
  max-width: 50%;

  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
}


.b-cards{
  .c-item-card__content{
    min-height: auto;
  }
}

.b-three-items {
  text-align: center;
}

.b-three-items__title {
  margin-bottom: 1.5rem;
  text-align: left;
}

.b-three-items__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.4rem;
  text-align: left;

  @include media-breakpoint-down(sm){
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down(sm){
    grid-template-columns: 1fr;
  }
}

.b-three-items__item {
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
  width: 100%;
  height: 100%;
  padding-bottom: 1.125rem;
}

.b-three-items__picture-container {
  height: 228px;
  margin-bottom: 1.25rem;

  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.b-three-items__item-title {
  font-size: 1.25rem;
  font-weight: $font-weights-bold;
  letter-spacing: 0;
  line-height: 1.2;
  color: var(--color-dark);
  padding: 0 1.75rem 0 1.25rem;
  margin: 0;

  @include media-breakpoint-down(xs){
    font-size: 1rem;
  }
}

.b-three-items__button {
  margin-top: 35px;
  text-align: center;
}
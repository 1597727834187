.c-linked-organisation-card{
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: var(--shadow-lighter);
  transition: 100ms ease-in-out;
  height: 100%;
  text-decoration: none !important;
}

.c-linked-organisation-card--nolink:hover{
  box-shadow: var(--shadow-lighter)!important;
}

.c-linked-organisation-card--nolink:active{
  background: none!important;
}

.c-linked-organisation-card__details{
  font-size: 0.875rem;
  margin-bottom: 0 !important;
}

.c-linked-organisation-card--popup{
  display: block!important;
  box-shadow: none!important;
  width:100%;
}

.c-linked-organisation-card--popup:hover{
  box-shadow: none!important;
}


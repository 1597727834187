

.b-authors{

}

.b-authors__container{
  padding: 2rem;
  box-shadow: var(--shadow-lighter);
  border-radius: 4px;
  position: relative;
  background-color: var(--color-card-background);
}

.b-authors__single-image{
  img{
    border-radius: 100%;
    height: 94px;
    width: 94px;
  }

  &.small{
    img{
      height: 55px;
      width: 55px;
    }
  }
}

.b-authors__single-content{
  padding-left: 2rem;
  max-width: calc(100% - 95px);

  &.small{
    padding-left: 1rem;
    max-width: calc(100% - 55px);
  }

  @include media-breakpoint-down(xs) {
    max-width: 100%;
    padding-left: 0;
    padding-top: 1rem;

    small{
      display: block;
    }
  }
}

.b-authors__title{
  font-size: 1.11rem;
}

.b-authors__author{
  position: relative;
  @include media-breakpoint-down(md) {
    position: unset;
  }
}

.b-authors__popup{
  padding: 1rem;
  visibility: hidden;
  background-color: var(--color-light);
  width: 558px;
  box-shadow: var(--shadow-lighter);
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: 2rem;
  left: 50%;
  margin-left: -170px;
  display: flex;

  small{
    display: block;
  }

  &.show{
    visibility: visible;
  }

  @include media-breakpoint-down(md) {
    left: 0;
    width: 100%;
    top: auto;
    margin-left: 0;
  }
}

.b-authors__author-link{
  text-decoration: underline;
  color: var(--color-primary);
  cursor: pointer;
  padding-right: 0.5rem;
  font-weight: 600;
  span{
    text-decoration: none;
    color: var(--color-dark);
  }
}


.c-subscription-group {
  background-color: var(--color-tertiary-24);
  padding: 1rem;
}

.c-subscription-group .form-check {
  padding-left: 0;
}

.c-subscription-group input[type=checkbox]:checked+label {
  background-color: transparent;
}
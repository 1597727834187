.c-epd__checkmark {
  &:before {
    color: green;
  }
}

.c-epd__times {
  font-size: 1.2em;

  &:before {
    color: red;
  }
}

.b-carousel {
  position: relative;

  @include in_editor {
    max-height: 300px;
    overflow: hidden;

    img {
      height: 300px;
    }
  }

  .tns-controls {
    button {
      position: absolute;
      height: 100%;
      width: 84px;
      background-color: rgba(0, 74, 55, 0.72);
      z-index: 1;
      border: none;
      backdrop-filter: blur(3px);
      color: white;
      font-size: 30px;

      @include media-breakpoint-down(sm) {
        height: 84px;
        bottom: 0;
      }
    }

    button[data-controls="prev"] {
      left: 0;
    }

    button[data-controls="next"] {
      right: 0;
    }
  }

  .tns-outer {
    position: relative;
  }

  .tns-nav {
    display: none;
  }
}

.b-carousel__block-title {
  display: inline-block;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.b-carousel__image-count {
  display: inline-block;
  margin-left: 0.9375rem;
  font-size: 1.0625rem;
  color: #848480;
}

.b-carousel__single-slider-container {
  width: 792px;
  height: 480px;
  position: relative;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 300px;
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    @include media-breakpoint-down(sm) {
      background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
      top: 0;
      bottom: auto;
      opacity: 1;
    }
  }
}

.tns-slide-active + .tns-slide-active {
  .b-carousel__single-slider-container{
    &:after {
      opacity: 1;
    }
  }
}

.tns-slide-active + .tns-slide-active + .tns-slide-active{
  .b-carousel__single-slider-container{
    &:after {
      opacity: 0;
    }
  }
}


.b-carousel__title {
  font-size: 1.375rem;
  line-height: 1.5625rem;
  font-weight: $font-weights-medium;
  color: white;
  left: 2.25rem;
  max-width: 92%;
  position: absolute;
  bottom: 3.4375rem;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    position: relative;
    margin-top: -400px
  }
}

.b-carousel__text {
  position: absolute;
  color: white;
  font-size: 0.85rem;
  line-height: 0.9375rem;
  bottom: 1rem;
  left: 2.25rem;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    position: relative;
    margin-top: -20px;
  }
}
.b-bar{
  margin-top: 0;
  @include media-breakpoint-down(sm){
    background-color: var(--color-secondary);
  }
}

.b-bar__wrapper{
  background-color: var(--color-secondary);
  padding: 1.2rem;
  position: relative;
  @include media-breakpoint-up(md){
    min-height: 85px;
  }
}

.b-bar__title{
  margin: 0.54rem 0;
  color: var(--color-light);
  @include media-breakpoint-up(md){
position: absolute;
    left:8.125rem;
  }
}

.b-bar__button{
  white-space: nowrap;
  @include media-breakpoint-up(md){
    position: absolute;
    right:8.125rem;
  }
}


.t-dossier-single__top-right{
  height: 162px;
  width: 162px;
  background-color: var(--color-tertiary-36);
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  i{
    font-size: 2.9rem;
    color: var(--color-primary);
  }
}

.t-dossier-single__top-left{
  max-width: 665px;
}

.c-dossier-single__icon-image {
  max-height: 80%;
  max-width: 80%;
  object-fit: contain;
}
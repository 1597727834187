.c-video-card__thumbnail {
  position: absolute;
  top: 0;
  width: 100%;
  height: 195px;

  img {
    object-fit: cover;
    width: 100%;
    height: 195px;
  }

  &:after {
    content: '\f04b';
    font-family: $font-awesome;
    font-weight: 900;
    font-size: 18px;
    text-align: center;
    line-height: 2.66;
    color:var(--color-light);
    display: block;
    height: 48px;
    width: 48px;
    background-color: var(--color-primary);
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 100%;
  }

  &.has-duration {

    &:after {
      top: -40px;
    }
  }

}

.c-video-card {
  padding-top: 187px;
  position: relative;
  background-color: $white;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);

  &:hover,
  &:focus {
    text-decoration: none;

    .c-video-card__title {
      text-decoration: underline;
    }
  }
}

.c-video-card__logo {
  display: block;
}

.c-video-card__duration {
  font-size: 13px;
  position: absolute;
  padding: 4px 8px;
  margin: auto;
  color: white;
  background-color: rgba(28, 28, 27, 0.7);
  border-radius: 4px;
  left: 0;
  right: 0;
  text-align: center;
  max-width: 60px;
  top: 110px;
}

.c-video-card__date {
  margin-bottom: 6px;
  color: var(--color-primary);
  text-transform: uppercase;
  font-size: 0.625rem;
  letter-spacing: 0.03125rem;
  font-weight: $font-weight-bold;

  &:hover,
  &:focus {
    text-decoration: none !important;
  }
}

.c-video-card__title {
  margin-top: 6px;
}
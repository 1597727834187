.b-grid-varied__position--center {
  text-align: center;
}

.b-grid-varied__position--right {
  text-align: right;
}

.b-grid-varied__position--left {
  text-align: left;
}

.b-grid-varied {
  .c-item-card__image {
    height: auto;
  }
}

.c-search-modal {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  height: 100%;

  border: 24px solid transparent;
  z-index: 5;

  background: var(--color-grey-2);

  flex-direction: column;

  display: none;
}

.c-search-modal--show {
  display: flex;
}


.c-search-modal__close {
  position: absolute;
  top: 0; right: 0;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-dark);
  border: 1px solid currentColor;
  border-radius: 4px;
  font-size: 24px;
  &:hover {
    box-shadow: var(--shadow-default);
  }
  &:focus {
    box-shadow: var(--shadow-inset);
    outline: none;
    background-color: var(--color-grey-1);
  }
}

.c-search-modal__content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    max-width: 100%;
  }
}

.c-search-modal__content-container--top {
  padding-bottom: 3.25rem;
  flex-basis: 45%;
}
.c-search-modal__content-container--bottom {
  padding-top: 3.25rem;
  flex-basis: 55%;
  overflow-y: scroll;
}

.c-search-box {
  width: 590px;
  max-width: 100%;
}

.c-search-box__title {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2rem;
  line-height: 1;
}

.c-search-box__form{
  display: flex;
  justify-content: space-between;
}

input[type=search].c-search-box__input {
  margin-right: 1rem;
  height: 60px;
  padding: 1em 1.5em;
}


input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  height: 1rem;
  width: 1rem;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/light/times.svg) no-repeat 50% 50%;
}

.c-topic-nav__title {
  text-transform: uppercase;
  font-size: .7778rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: block;
  text-align: center;
  width: 100%;

}
.c-topic-nav__char-links {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 760px;
  max-width: 100%;
  margin:0; padding:0;
}

.c-topic-nav__char-link {
  border-radius: 4px;
  margin: .275rem;
  width: 36px; height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
  border: 1px solid currentColor;
  transition: none;
  font-size: .7778rem;
  &:hover, &:focus {
    text-decoration: none;
    color: var(--color-secondary);
    outline: none;
  }
}
.c-topic-nav__char-link--active {
  background: var(--color-secondary);
  border-color: var(--color-secondary);
  color: var(--color-light);
  font-weight: bold;
  &:hover, &:focus {
    color: var(--color-light);
    outline: none;
  }
}

.c-topic-nav__noresults {
  font-size: .8rem;
}

.c-topic-nav__results {
  list-style: none;
  padding: 0; margin: 0;
}
.c-topic-nav__item {
  overflow: hidden;
  margin-bottom: .5rem;
}
.c-topic-nav__link {
  color: var(--color-primary);
  text-decoration: underline;
  font-size: .8889rem;
  //white-space: nowrap;
  //text-overflow: ellipsis;
  //overflow-x: hidden;
  display: inline-block;
  line-height: 1.25;
  max-width: 100%;
  &:hover, &:focus {
    color: var(--color-secondary);
  }
}

.c-loader__spinner:not(.c-loader__spinner--hide) + .c-topic-nav__results-list {
  display: none;
}






.b-text-framework{
  border: 1px solid var(--color-primary);
  border-radius: $border-radius;
  background-color: var(--color-tertiary-24);
}

.b-text-framework__content{
  padding: 2rem;

  @include media-breakpoint-down(md) {
    padding: 1rem;
  }

  h2, h3{
    font-size: 1.4rem !important;
    margin-bottom: 1rem;

    @include media-breakpoint-down(md) {
      font-size: 1.2rem !important;
    }
  }
  p{
    margin-bottom: 0;
  }

  .c-button{
    color: var(--color-light);

    @include in_editor() {
      background-color: $gray-800;
      color: white;
    }
  }
}
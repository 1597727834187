.learndash-wrapper .ld-focus {

  .ld-focus-sidebar {
    top: 116px;
    z-index: 1;
    background-color: var(--color-tertiary-12) !important;
    border-right: 0;
    padding: 0 !important;
    overflow-y: auto;
    overflow-x: hidden;

    .ld-progress {
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0;
      padding: 10px 20px !important;
      min-height: 60px;

      .ld-progress-heading .ld-progress-stats
      {

        .ld-progress-percentage {
          color: var(--color-primary);
          font-size: 12px;
        }

        .ld-progress-steps {
          color: var(--color-grey-5);
          font-size: 12px;
          font-weight: 500;
        }
      }

      .ld-progress-bar-percentage {
        width: 20%;
        background-color: var(--color-primary);
      }
    }

    .ld-focus-sidebar-wrapper {
      height: unset;
    }

    .ld-course-navigation-heading,
    .ld-course-navigation-heading .ld-focus-sidebar-trigger {
      background-color: var(--color-primary) !important;
      min-height: unset;
      height: unset;

      h3 {
        padding: 0;
        font-weight: 600;
      }
    }

    .ld-lesson-item-preview-heading .ld-lesson-title {
      color: var(--color-dark) !important;
      font-weight: 600 !important;
      font-size: 15px !important;
    }

    .ld-is-current-lesson .ld-lesson-title {
      color: var(--color-primary) !important;
    }

    .ld-course-navigation {

      .ld-lesson-item.ld-is-current-lesson{
        .ld-lesson-item-preview-heading .ld-status-incomplete,
        .ld-lesson-title .ld-status-incomplete {
          border-color: rgb(226, 231, 237)!important;
        }
      }

      .ld-topic-list.ld-table-list .ld-table-list-item .ld-table-list-item-preview {
        padding:0;
        .ld-topic-title:hover{
          color: var(--color-primary);
        }
      }
      .ld-status-icon {
        height: 20px;
        width: 20px;
        flex: 0 0 20px;
        margin-top:0;
        :before{
          font-size: 11px;
        }
      }
      .ld-icon-quiz{
        &:before{
          font-size: 18px;
        }
      }

      .ld-status-icon.ld-status-in-progress {
        border-color: var(--color-primary) #e2e7ed #e2e7ed var(--color-primary);
      }

      .ld-lesson-item .ld-lesson-item-preview {
        background-color: var(--color-tertiary-12);
      }

      .ld-lesson-item-section-heading {
        padding-bottom: 1rem !important;
        font-size: 20px;
        border-bottom: 2px solid var(--color-light);
      }
    }

    .ld-table-list-items{
      padding:0!important;
      .ld-table-list-item{
        padding: 9px 0;

      }
    }

    .ld-status-icon.ld-status-complete {
      background-color: var(--color-primary) !important;
      border-color: var(--color-primary) !important;
    }

    .ld-status-icon.ld-quiz-complete {
      color: var(--color-primary);
    }

    .ld-course-navigation {

      .ld-lesson-item {
        border-bottom: 2px solid var(--color-light);
      }

      .ld-topic-list.ld-table-list {
        background: var(--color-light);
      }

      .ld-topic-title{
        line-height: 20px;
        font-weight: 500;
      }

      .ld-is-current-item{
        .ld-topic-title{
          font-weight: 700;

        }
      }


      .ld-lesson-item-expanded {
        background: var(--color-light);
      }

      .ld-lesson-item-preview a.ld-lesson-item-preview-heading {
        color: var(--color-primary) !important;

        &:hover {
          color: var(--color-primary) !important;
        }
      }
    }

    .ld-expand-button {
      flex-direction: row-reverse;
      justify-content: space-between !important;
      .ld-text{
        font-size: 11px;
        font-weight: 500;
        .ld-sep{
          transform: translateY(50%);
          font-size: 0;
          display: inline-block;
          background: var(--color-grey-5);
          height:4px;
          width: 4px;
          margin: 0 0.5rem;
          border-radius: 50%;
        }
      }
    }

    .ld-icon-content:before {
      content: '';
    }

    .ld-expand-button.ld-button-alternate .ld-icon {
      background: var(--color-primary);
      hyphens: manual;
    }
  }

  .ld-icon-content, .ld-icon-materials {
    display: none;
  }

  .ld-focus-main {

    .ld-focus-content {
      position: relative;
      top: 116px;
    }

    .ld-tabs .ld-tabs-navigation {
      .ld-text {
        font-weight: 600;
      }

      .ld-tab {
        padding: 0.75rem 0.5rem;
        border: 0;

        &.ld-active {
          color: var(--color-primary);

          &:after {
            background: var(--color-primary);
            height: 3px;
          }
        }
      }

      &:after {
        height: 1px;
      }
    }

    .ld-table-list{

      .ld-table-list-header {
        background-color: var(--color-primary);

        .ld-lesson-list-progress {
          display: none;
        }
      }

      a.ld-table-list-item-preview{
        color: var(--color-primary);
        font-size: 14px;
        font-weight: 500;
        &:hover{
          color:  var(--color-primary-darkest);
        }
      }
    }

    .ld-alert-warning {
      background-color: var(--color-tertiary-24);
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 4px;
      border-color: var(--color-primary);
      min-height: unset;

      .learndash-link-previous-incomplete{
        background-color: var(--color-primary);
        white-space: nowrap;
        color: var(--color-light);
        border-radius: 4px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      .ld-alert-icon{
         background: var(--color-secondary);
      }
    }
  }

  .ld-breadcrumbs,
  .ld-focus-main .ld-focus-content .ld-lesson-status .ld-progress,
  .ld-focus-main .ld-focus-content .ld-topic-status .ld-progress,
  .ld-content-actions .ld-content-action .ld-course-step-back,
  .ld-content-actions > a
  {
    display: none !important;
  }

  .ld-button,
  .ld-content-actions .ld-content-action .sfwd-mark-complete input[type=submit],
  .wpProQuiz_button,
  .wpProQuiz_button2,
  .wpProQuiz_certificate a.btn-blue
  {
    border-radius: 10px;
    background: var(--color-primary)!important;
    &:hover {
      background-color: var(--color-primary-darkest);
    }
  }
  .quiz_continue_link a#quiz_continue_link{
    border-radius: 10px;
    background: var(--color-secondary);
    &:hover {
      background-color: var(--color-secondary-darkest);
    }
  }
}

body.admin-bar .learndash-wrapper .ld-focus-sidebar {
  top: 148px;
}

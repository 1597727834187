.b-members-content-divider {

}

.b-members-content-divider__placeholder{
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-primary);
}
